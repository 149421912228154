import React from 'react';
import NumberFormat from 'react-number-format';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import PropTypes from 'prop-types';

const InputNumberFormatter = ({
  type,
  input,
  placeholder,
  disabled,
  inputFormat,
  allowNegative,
  mask,
  meta: { touched, error },
  isPhone,
}) => (
  <div className={isPhone ? 'input-group' : ''}>
    {isPhone && (
      <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon1">
          +56
        </span>
      </div>
    )}
    <NumberFormat
      {...input}
      id={input.name}
      type={type}
      className="form-control"
      placeholder={placeholder}
      disabled={disabled}
      allowNegative={allowNegative}
      format={inputFormat}
      mask={mask}
    />
    {touched && error && <small className={`invalid ${isPhone ? 'w-100' : ''}`}>{error}</small>}
  </div>
);

InputNumberFormatter.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  disabled: PropTypes.bool,
  inputFormat: PropTypes.string,
  allowNegative: PropTypes.bool,
  mask: PropTypes.arrayOf(PropTypes.string),
  isPhone: PropTypes.bool,
};

InputNumberFormatter.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false,
  inputFormat: null,
  allowNegative: false,
  mask: [],
  isPhone: false,
};

export default InputNumberFormatter;
