import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import quote from './quote/reducers/quote';
import subscription from './subscription/reducers/subscription';
import { SET_INITIAL_STATE } from './commons/actions/constants';

const createRootReducer = history =>
  combineReducers({
    form,
    quote,
    subscription,
    router: connectRouter(history),
  });

const resetEnhancer = rootReducer => (state, action) => {
  if (action.type === SET_INITIAL_STATE) {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export { createRootReducer, resetEnhancer };
