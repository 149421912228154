import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as creators from './creators';
import { URL_QUOTE_PLANS, URL_QUOTE, CLOSING_TOKEN_HEADER_NAME } from '../../constants';
import {
  formatDateToIsoString,
  parserRut,
  calculateUfAmountFromPlan,
  generateUUID,
} from '../../commons/helpers';
import { actionHandleError } from '../../commons/actions/creators';

const quoteActions = {
  *fetchQuotePlans() {
    try {
      yield put(creators.actionSetPlanLoading(true));
      const { data: plans } = yield call(axios.get, URL_QUOTE_PLANS, {
        params: { category: 2, product: 20225 },
      });

      yield put(creators.actionSetQuotePlans(plans));
      yield put(creators.actionSetPlanLoading(false));
    } catch (error) {
      yield put(actionHandleError());
      yield put(creators.actionSetPlanLoading(false));
    }
  },
  *submitQuoteForm({ payload }) {
    try {
      yield put(creators.actionSetLoading(true));
      yield put(creators.actionSetQuoteForm(payload));

      const formattedDate = formatDateToIsoString(payload.birthdate, 'DD-MM-YYYY');

      const quote = {
        channel: 'INTERNET',
        executive: '33285',
        branch: '8681',
        payload: {
          insured: {
            document: {
              type: 'RUT',
              number: parserRut(payload.rut),
            },
            email: payload.email,
            phone: `+56${payload.phone}`,
            birthdate: formattedDate,
          },
        },
      };
      const uuid = generateUUID();
      const config = {
        headers: {
          'x-trace': uuid,
        },
      };

      const { data, headers } = yield call(axios.post, URL_QUOTE, quote, config);

      const closingToken = headers[CLOSING_TOKEN_HEADER_NAME] || '';
      const plans = data.plans.map(plan => ({
        ...plan,
        amount: calculateUfAmountFromPlan(plan.properties.id),
      }));

      yield put(creators.actionSetSubProducts(plans));

      const quoteResponse = {
        quoteId: data.id,
        consecutive: data.consecutive,
        branch: data.branch,
        control: data.control,
      };

      yield put(creators.actionSetQuoteResponse(quoteResponse));
      yield put(push('/subproducts'));
      yield put(creators.setUUID(uuid));
      yield put(creators.setClosingToken(closingToken));
      yield put(creators.actionSetLoading(false));
    } catch (error) {
      yield put(actionHandleError());
      yield put(creators.actionSetLoading(false));
    }
  },
  *selectSubProduct({ payload }) {
    try {
      yield put(creators.actionSetSelectedSubProduct(payload));

      yield put(push('/subscription'));
    } catch (error) {
      yield put(actionHandleError());
    }
  },
  *backToQuote() {
    try {
      yield put(push('/'));
    } catch (error) {
      yield put(actionHandleError());
    }
  },
};

export default quoteActions;
