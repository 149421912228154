import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { Field } from 'redux-form';
import { PropTypes } from 'prop-types';
import { Element } from 'react-scroll';
import QuoteInformation from './QuoteFormInformation';
import QuoteBox from './QuoteFormFloatingBox';
import ScrollTopButton from './ScrollTopButton';
import Input from '../../commons/input/Input';
import InputNumberFormatter from '../../commons/components/inputs/InputNumberFormatter';
import Loading from '../../commons/loading/Loading';
import Container from '../../layout/Container';
import { formatRut, cleanRut, hashData } from '../../commons/helpers';
import './assets/quote.css';

export class QuoteForm extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      ReactGA.event('funnel_step_viewed', {
        funnel_name: 'contratacion-seguro',
        product_name: 'web-vida-legado',
        funnel_step_name: 'paso-visita',
        assist_type: 'autoasistido',
      });
    }
  }

  render() {
    const { handleSubmit, submitting, loading } = this.props;

    const handleClick = event => {
      window.sessionStorage.setItem('hashID', hashData(cleanRut(event.target.rut.value)));
      handleSubmit(event);
    };

    return (
      <Container>
        {!loading ? (
          <div className="relative">
            <div id="banner" className="w-100" />
            <QuoteBox />
            <div className="container">
              <div className="row">
                <Element name="inicio" className="col-12 mt60">
                  <h1 className="text-center mb5 px-37">
                    Bienvenido al Cotizador
                    <br />
                    Seguro de Vida con Ahorro
                  </h1>
                  <h2 className="text-center text-primary mb50 px-22">
                    Cuida el futuro de los que amas y además ahorra
                  </h2>
                </Element>
                <div className="col-12">
                  <form onSubmit={handleClick} noValidate>
                    <div className="row flex-column">
                      <div className="col-12 col-sm-8 col-md-7 col-lg-6 mx-auto form-group mb20">
                        <label htmlFor="rut">Rut</label>
                        <Field
                          name="rut"
                          component={Input}
                          placeholder="Ej 12.345.678-9"
                          normalize={formatRut}
                        />
                      </div>
                      <div className="col-12 col-sm-8 col-md-7 col-lg-6 mx-auto form-group mb20">
                        <label htmlFor="birthdate">Fecha de nacimiento</label>
                        <Field
                          name="birthdate"
                          placeholder="31-12-2000"
                          inputFormat="##-##-####"
                          mask={['d', 'd', 'm', 'm', 'y', 'y', 'y', 'y']}
                          component={InputNumberFormatter}
                        />
                      </div>
                      <div className="col-12 col-sm-8 col-md-7 col-lg-6 mx-auto form-group mb20">
                        <label htmlFor="email">Correo</label>
                        <Field
                          name="email"
                          component={Input}
                          placeholder="ejemplo@dominio.com"
                          type="email"
                        />
                        <div className="alert-message alert-message--yellow mb-4">
                          <span>
                            <span className="flex align-middle">
                              <img
                                className="flex align-middle"
                                src="https://sfestaticos.blob.core.windows.net/full-cobertura/assets/img/2022/info-ico.svg"
                                alt="info"
                              />
                            </span>
                            <span className="col-3">
                              Nuestro medio de contacto principal contigo será el correo que nos
                              proporciones.
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-sm-8 col-md-7 col-lg-6 mx-auto form-group mb40">
                        <label htmlFor="phone">Teléfono</label>
                        <Field
                          name="phone"
                          placeholder="941234567"
                          inputFormat="#########"
                          component={InputNumberFormatter}
                          isPhone
                        />
                      </div>
                      <div className="col-12 col-sm-8 col-md-3 col-lg-5 mx-auto form-group">
                        <button
                          id="cotizar"
                          className="btn primary"
                          type="submit"
                          disabled={submitting}
                        >
                          Cotizar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-12 mt20">
                  <p className="px-14 text-dark-gray text-center">Pago exclusivo con:</p>
                  <div className="row">
                    <div className="col-12 justify-content-center align-items-center d-flex mx-auto">
                      {/* <img
                      src="//sfestaticos.blob.core.windows.net/guideline/payment-logos/CMR.svg"
                      alt="Tarjeta CMR"
                      width="70px"
                    /> */}
                      <img
                        className="mt5"
                        src="//sfestaticos.blob.core.windows.net/guideline/payment-logos/webpay.svg"
                        alt="Tarjetas de Crédito"
                        width="110px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <QuoteInformation />
            <ScrollTopButton />
          </div>
        ) : (
          <Loading />
        )}
      </Container>
    );
  }
}

QuoteForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default QuoteForm;
