import React, { Component } from 'react';
import { Element, Link } from 'react-scroll';
import { Collapse, Modal, ModalBody } from 'reactstrap';
import LogoFilled from '../../assets/svg/logo-vida-filled.svg';
import './assets/quote.css';
import { activePromo } from '../../commons/helpers';

class QuoteFormInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseReasons: true,
      collapseBenefits: true,
      collapseCoverage: true,
      collapseNotCover: true,
      collapseRequirements: true,
      isDesktop: true,
      modalLegal: false,
      modalTerms: false,
    };
  }

  componentDidMount() {
    this.isDesktop();
    window.addEventListener('resize', this.isDesktop);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.isDesktop);
  }

  isDesktop = () => {
    const breakpoint = window.innerWidth > 768;
    this.setState({
      isDesktop: breakpoint,
      collapseReasons: breakpoint,
      collapseBenefits: breakpoint,
      collapseCoverage: breakpoint,
      collapseNotCover: breakpoint,
      collapseRequirements: breakpoint,
    });
  };

  toggleLegal = () => this.setState(prevState => ({ modalLegal: !prevState.modalLegal }));

  toggleTerms = () => this.setState(prevState => ({ modalTerms: !prevState.modalTerms }));

  toggleReasons = () =>
    this.setState(prevState => ({ collapseReasons: !prevState.collapseReasons }));

  toggleBenefits = () =>
    this.setState(prevState => ({ collapseBenefits: !prevState.collapseBenefits }));

  toggleCoverage = () =>
    this.setState(prevState => ({ collapseCoverage: !prevState.collapseCoverage }));

  toggleNotCover = () =>
    this.setState(prevState => ({ collapseNotCover: !prevState.collapseNotCover }));

  toggleRequirements = () =>
    this.setState(prevState => ({ collapseRequirements: !prevState.collapseRequirements }));

  render() {
    const {
      collapseReasons,
      collapseBenefits,
      isDesktop,
      modalLegal,
      modalTerms,
      collapseCoverage,
      collapseNotCover,
      collapseRequirements,
    } = this.state;

    return (
      <div>
        {/* Botonera */}
        <div className="container-fluid border-top border-bottom d-none d-sm-block mb40 mt50">
          <div className="container">
            <div className="row">
              <div className="col-12 pt20 pb20 justify-content-between d-flex">
                <Link
                  className="cursor-pointer text-dark-gray"
                  activeClass="active"
                  to="razones"
                  spy
                  smooth
                  offset={-50}
                  duration={500}
                >
                  Razones para tener el Seguro
                </Link>

                <Link
                  className="cursor-pointer text-dark-gray"
                  activeClass="active"
                  to="beneficios"
                  spy
                  smooth
                  offset={-50}
                  duration={500}
                >
                  Beneficios
                </Link>

                <Link
                  className="cursor-pointer text-dark-gray"
                  activeClass="active"
                  to="queCubre"
                  spy
                  smooth
                  offset={-50}
                  duration={500}
                >
                  Coberturas
                </Link>

                <Link
                  className="cursor-pointer text-dark-gray"
                  activeClass="active"
                  to="noCubre"
                  spy
                  smooth
                  offset={-50}
                  duration={500}
                >
                  ¿Qué no cubre?
                </Link>

                <Link
                  className="cursor-pointer text-dark-gray"
                  activeClass="active"
                  to="requerimientos"
                  spy
                  smooth
                  offset={-50}
                  duration={500}
                >
                  Requisitos
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Botonera */}
        <div className="container-fluid bg-light-gray mt100 pb40">
          <div className="container text-center">
            <div className="row">
              <div className="col-12 relative">
                <img className="logo-vida-filled" src={LogoFilled} alt="Logo Vida" width="134" />
              </div>
              <div className="col-12 col-sm-10 mx-auto mt100">
                <p>
                  Nunca es pronto para pensar en el futuro y proteger a los que más quieres.
                  Contrata tu&nbsp;
                  <b>Seguro de Vida con Ahorro 100% Online y Ahorra 50%</b>
                  &nbsp;de lo pagado mensualmente.
                </p>
                <p>
                  Este Seguro te permitirá&nbsp;
                  <b>proteger a tu familia</b>
                  &nbsp;ante posibles problemas económicos en caso de que fallezcas junto con&nbsp;
                  <b>ahorrar para lograr tus objetivos.</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`container-fluid bg-white ${isDesktop ? 'pt30 pb30' : ''}`}>
          <div className="container">
            <div className="row">
              <div
                className={`col-12 border-bottom pt30 pb30 d-block d-md-none ${
                  collapseReasons ? 'mb20' : ''
                }`}
                onClick={this.toggleReasons}
                role="presentation"
              >
                <i className="material-icons align-middle">keyboard_arrow_right</i>
                Razones para tener tu seguro
              </div>
              <Collapse className="col-12" isOpen={collapseReasons}>
                <Element name="razones" className="col-12">
                  <h2 className="px-24 text-center mb40">
                    Razones para tener un Seguro de Vida con Ahorro
                  </h2>
                </Element>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-3">
                    <figure className="col-12 shadow home-card">
                      <img
                        src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/informacion/familiaGarantia.svg"
                        alt="Familia protegida"
                      />
                      <figcaption>
                        <p>
                          Garantizar a tu familia, el futuro que quieres para ellos en caso de que
                          te pase algo.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <figure className="col-12 shadow home-card">
                      <img
                        src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/informacion/finanzas.svg"
                        alt="Finanzas"
                      />
                      <figcaption>
                        <p>
                          Proteger financieramente a tu familia y a aquellos que dependen de ti,
                          ante tu fallecimiento.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <figure className="col-12 shadow home-card">
                      <img
                        src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/informacion/ahorra.svg"
                        alt="Ahorro"
                      />
                      <figcaption>
                        <p>Ahorrar para planes y proyectos futuros.</p>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3">
                    <figure className="col-12 shadow home-card">
                      <img
                        src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/informacion/FamiliaCuida.svg"
                        alt="Familia"
                      />
                      <figcaption>
                        <p>Cuida el futuro de los que más amas.</p>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
        {/* Container */}
        <div className={`container-fluid ${isDesktop ? 'bg-light-gray pt30 pb30' : ''}`}>
          <div className="container">
            <div className="row">
              <div
                className={`col-12 border-bottom pt30 pb30 d-block d-md-none ${
                  collapseBenefits ? 'mb20' : ''
                }`}
                onClick={this.toggleBenefits}
                role="presentation"
              >
                <i className="material-icons align-middle">keyboard_arrow_right</i>
                Ver beneficios
              </div>
              <Collapse className="col-12" isOpen={collapseBenefits}>
                <Element name="beneficios" className="col-12">
                  <h2 className="px-24 text-center mb40">
                    Beneficios del Seguro de Vida con Ahorro
                  </h2>
                </Element>
                <ul className="col-12 d-flex flex-wrap text-primary mx-auto mb0 li-custom li-check">
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">50% de lo pagado va a un fondo de ahorro</p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">1% de Rentabilidad Anual Garantizada</p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">
                      A partir del mes 13, el ahorro a aumenta a un 60%
                    </p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">Hasta 20% de dcto. en Ópticas Place Vendôme</p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">4 retiros de hasta el 100% de lo ahorrado</p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">
                      Precios preferentes en Red Dental de clínicas asociadas a Metlife
                    </p>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
        </div>
        {/* Container */}
        {/* Container Coberturas y Exclusiones */}
        <div className={`container-fluid bg-white ${isDesktop ? 'pt30 pb30' : ''}`}>
          <div className="container">
            <div className="row">
              <div
                className={`col-12 border-bottom pt30 pb30 d-block d-md-none ${
                  collapseCoverage ? 'mb20' : ''
                }`}
                onClick={this.toggleCoverage}
                role="presentation"
              >
                <i className="material-icons align-middle">keyboard_arrow_right</i>
                Ver qué cubre
              </div>
              <Collapse className="col-12" isOpen={collapseCoverage}>
                <Element name="queCubre" className="col-12">
                  <h2 className="px-24 text-center mb40">
                    ¿Qué cubre el Seguro de Vida con Ahorro?
                  </h2>
                </Element>
                <ul className="col-12 col-md-6 col-lg-3 d-flex flex-wrap text-primary mx-auto mb0 li-custom li-check">
                  <li className="col-12">
                    <p className="text-dark-gray">Fallecimiento natural.</p>
                  </li>
                  <li className="col-12">
                    <p className="text-dark-gray mb0">Fallecimiento accidental.</p>
                  </li>
                </ul>
                <div className="col-12 mt30">
                  <p className="mb0">
                    <small>
                      * Si contratas después de cumplidos los 51 años y antes de cumplir 61 años,
                      pertenecerás al Tramo B, por lo que tendrás un 25% fallecimiento natural
                      &nbsp;y&nbsp;75% fallecimiento accidental.
                    </small>
                  </p>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
        {/* fin Container Coberturas y Exclusiones */}
        {/* Container Coberturas y Exclusiones */}
        <div className={`container-fluid ${isDesktop ? 'bg-light-gray pt30 pb30' : ''}`}>
          <div className="container">
            <div className="row">
              <div
                className={`col-12 border-bottom pt30 pb30 d-block d-md-none ${
                  collapseNotCover ? 'mb20' : ''
                }`}
                onClick={this.toggleNotCover}
                role="presentation"
              >
                <i className="material-icons align-middle">keyboard_arrow_right</i>
                Ver qué no cubre
              </div>
              <Collapse className="col-12" isOpen={collapseNotCover}>
                <Element name="noCubre" className="col-12">
                  <h2 className="px-24 text-center mb40">
                    ¿Qué no cubre el Seguro de Vida con Ahorro?
                  </h2>
                </Element>
                <ul className="col-12 d-flex flex-wrap text-primary mx-auto li-custom li-cross">
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">
                      Guerra, declarada o no declarada, invasión, acción de un enemigo extranjero,
                      hostilidades u operaciones bélicas, ya sea con o sin declaración de guerra.
                    </p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">
                      Suicidio, automutilación o autolesión cualquiera sea la causa que lo origine,
                      aun cuando el asegurado hubiera actuado privado de razón. No obstante, la
                      compañía aseguradora pagará el monto asegurado a los beneficiarios, si el
                      fallecimiento ocurriera como consecuencia de suicidio, siempre que hubieran
                      transcurrido dos (2) años completos e ininterrumpidos desde la fecha de
                      contratación del seguro.
                    </p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">
                      Participación del asegurado o beneficiario en un acto calificado como delito,
                      cometido en calidad de autor, cómplice o encubridor.
                    </p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">
                      La conducción de cualquier vehículo por parte del asegurado, encontrándose
                      bajo estado de ebriedad o bajo los efectos de cualquier narcótico o droga,
                      conforme a los límites establecidos en la normativa vigente a la fecha de
                      siniestro.
                    </p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">
                      Negligencia, imprudencia o culpa grave del asegurado.
                    </p>
                  </li>
                  <li className="col-12 col-sm-6">
                    <p className="text-dark-gray">
                      Enfermedad, situaciones o dolencias preexistentes, en los términos indicados
                      en el artículo 591 del Código de Comercio.
                    </p>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
        </div>
        {/* fin Container Coberturas y Exclusiones */}
        {/* Container Requerimientos */}
        <div className={`container-fluid ${isDesktop ? 'pt30 pb30' : 'mb20'}`}>
          <div className="container">
            <div className="row">
              <div
                className={`col-12 border-bottom pt30 pb30 d-block d-md-none ${
                  collapseRequirements ? 'mb20' : ''
                }`}
                onClick={this.toggleRequirements}
                role="presentation"
              >
                <i className="material-icons align-middle">keyboard_arrow_right</i>
                Ver requerimientos
              </div>
              <Collapse className="col-12" isOpen={collapseRequirements}>
                <Element name="requerimientos" className="col-12">
                  <h2 className="px-24 text-center mb40">
                    Requisitos para Contratar el Seguro de Vida con Ahorro
                  </h2>
                </Element>
                <ul className="col-12 col-sm-10 d-flex flex-wrap text-primary mx-auto li-custom li-check">
                  <li className="col-12">
                    <p className="text-dark-gray">
                      Edad de Ingreso: entre 18 y 50 años (Tramo A) y entre 51 y 60 años (Tramo B).
                    </p>
                  </li>
                  <li className="col-12">
                    <p className="text-dark-gray">
                      Se debe completar una declaración personal de salud, en caso que asegurado
                      responda SI a cualquiera de las preguntas, el riesgo será aceptado excluyendo
                      la prexistencia declarada.
                    </p>
                  </li>
                  <li className="col-12">
                    <p className="text-dark-gray">
                      El asegurado debe designar beneficiarios, nombre, rut, porcentaje de
                      participación y email.
                    </p>
                  </li>
                  <li className="col-12">
                    <p className="text-dark-gray">
                      La incorporación a esta póliza queda limitada a un seguro por cliente
                      asegurado.
                    </p>
                  </li>
                  <li className="col-12">
                    <p className="text-dark-gray">Medios de Pago: CMR y Transbank.</p>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
        </div>
        {/* fin Container Requerimientos */}
        <div className="container-fluid bg-white pt30 pb30">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="px-16 mb20">Asegura</h3>
                <img
                  src="//sfestaticos.blob.core.windows.net/guideline/companies/metlife.svg"
                  alt="Metlife"
                  width="140px"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Legales */}
        <div className="container mb50">
          <div className="row">
            <div className="col-12 text-center pb20 pt20">
              {/* eslint-disable-next-line */}
              <a
                className="text-dark-gray mr10 d-inline-block legal-notes-link cursor-pointer"
                role="presentation"
                onClick={this.toggleLegal}
              >
                Notas legales
              </a>
              <span className="mr10">-</span>
              {/* eslint-disable-next-line */}
              <a
                className="text-dark-gray d-inline-block terms-conditions-link cursor-pointer"
                role="presentation"
                onClick={this.toggleTerms}
              >
                Términos y condiciones
              </a>
            </div>
          </div>
        </div>
        <Modal isOpen={modalLegal} toggle={this.toggleLegal} size="lg">
          <div className="modal-header">
            <h5 className="modal-title mb30">Notas Legales</h5>
            <h6 className="text-primary">Seguro Vida con Ahorro 100% OnLine</h6>
            <button type="button" className="close" onClick={this.toggleLegal}>
              <i className="material-icons">close</i>
            </button>
          </div>
          <ModalBody>
            <ul className="px-14">
              <li>
                <p>La contratación de este seguro es voluntaria.</p>
              </li>
              <li>
                <p>
                  La póliza de tu seguro contratado será enviada al correo electrónico que nos
                  indicaste, dentro de 72 horas.
                </p>
              </li>
              <li>
                <p>
                  Si contratas el seguro, puedes retractarte dentro de los 35 días siguientes a su
                  contratación.
                </p>
              </li>
              <li>
                <p>
                  Puedes anular tu seguro contratado llamando al 600 390 1000 o +56 22 390 6570.
                  Desde esa fecha dejarás de tener cobertura y si corresponde, se devolverá el
                  monto de prima proporcional. El pago se realizará al mismo medio de pago de tu
                  seguro.
                </p>
              </li>
              <li>
                <p>
                  El beneficio de descuento en ópticas no constituye cobertura y es de exclusiva
                  responsabilidad de Place Vendome
                </p>
              </li>
              <li>
                <p>Oferta válida desde el 6 de febrero de 2019, stock ilimitado.</p>
              </li>
              <li>
                <p>
                  Sólo para personas naturales, de entre 18 y 60 años, que contraten 100% online un
                  Seguro de Vida con Ahorro.
                </p>
              </li>
              <li>
                <p>Condiciones Generales del Seguro según POL 2 2020 0032.</p>
              </li>
              <li>
                <p>
                  Intermedia Seguros Falabella quien asume las responsabilidades propias de su
                  intermediación.
                </p>
              </li>
              <li>
                <p>
                  El pago con tarjeta de crédito puede tener costos asociados. Consulte al emisor
                  para más información.
                </p>
              </li>
            </ul>
            {activePromo() && (
              <div>
                <h6 className="text-primary px-18 mt30">Campaña 2 Cuotas Gratis + Telemedicina</h6>
                <ul className="px-14">
                  <li>
                    <p>
                      Oferta válida desde el 4 de Junio 2020 hasta el 30 de Junio del 2020, stock
                      ilimitado.
                    </p>
                  </li>
                  <li>
                    <p>
                      Sólo para personas naturales, de entre 18 y 60 años, que contraten 100%
                      online un Seguro de Vida con Ahorro.
                    </p>
                  </li>
                  <li>
                    <p>
                      El beneficio de cuotas gratis se aplicará para las cuotas 2 y 6, siempre que
                      los pagos se encuentren al día.
                    </p>
                  </li>
                  <li>
                    <p>
                      La gratuidad del seguro de vida con ahorro en los meses en campaña, será sólo
                      respecto del costo de la cobertura de vida, no contribuyendo dichos meses al
                      ahorro. Intermedia Seguros Falabella quien asume las responsabilidades
                      propias de su intermediación.
                    </p>
                  </li>
                  <li>
                    <p>
                      El beneficio de descuento en ópticas no constituye cobertura y es de
                      exclusiva responsabilidad de Place Vendome
                    </p>
                  </li>
                  <li>
                    <p>
                      El beneficio de telemedicina será efectivo por 3 meses desde la contratación,
                      no constituye cobertura y es de exclusiva responsabilidad de Mediclic.
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </ModalBody>
        </Modal>
        <Modal isOpen={modalTerms} toggle={this.toggleTerms} size="lg">
          <div className="modal-header">
            <h5 className="modal-title mb30">Términos y condiciones</h5>
            <h6 className="text-primary">Seguro Vida con Ahorro 100% Online</h6>
            <button type="button" className="close" onClick={this.toggleTerms}>
              <i className="material-icons">close</i>
            </button>
          </div>
          <ModalBody>
            <ul className="px-14">
              <li>
                <p>
                  Nuestro compromiso es ofrecerte los productos y servicios que mejor se acomoden a
                  tus tus tus necesidades. Por ello, al aceptar estos términos y condiciones,
                  autorizas expresamente a Seguros Falabella, de conformidad al artículo 4° de la
                  Ley N°19.628 sobre Protección de la Vida Privada, a tratar los datos personales
                  que nos entregues cuando (i) visitas nuestras sucursales, (ii) utilizas nuestro
                  sitio sitio web, nuestros servicios móviles, o bien cuando (iii) interactúas con
                  nosotros a través de correo electrónico o con nuestra plataforma de atención
                  telefónica.
                </p>
              </li>
              <li>
                <p>
                  En especial, se entiende por &quot;dato personal&quot;, tu nombre, RUT,
                  domicilio, teléfono, correo electrónico, datos de geolocalización, tu historial
                  de navegación, y tu historial de compras.
                </p>
              </li>
              <li>
                <p>
                  Estos datos sólo serán tratados para (i) el llenado automático de los documentos
                  asociados a tus transacciones, (ii) la prestación de los servicios que contratas
                  y la entrega de los productos que compras, (iii) el envío de comunicaciones
                  promocionales o publicitarias y la exhibición de ofertas y recomendaciones de
                  productos y servicios (las que pueden estar basadas en una caracterización a
                  partir de tus datos personales, y/o tus patrones de compra, uso y visita de
                  nuestro sitio web, servicios móviles y tiendas), o bien para (iv) ponernos en
                  contacto contigo en relación a opiniones y comentarios que nos puedas dar para
                  mejorar tu experiencia como cliente.
                </p>
              </li>
              <li>
                <p>
                  Los datos que nos entregues solo podrán ser tratados por Falabella y/o sus
                  empresas relacionadas (*), por sí o a través de sus proveedores de servicios y
                  exclusivamente para las finalidades indicadas en el párrafo anterior.
                </p>
              </li>
              <li>
                <p>
                  En todo momento podrás revocar tu autorización, y dispondrás siempre de los
                  derechos de aclaración, rectificación, cancelación y oposición, y todos los demás
                  derechos que confiere la Ley N° 19.628 sobre Protección de la Vida Privada. Para
                  ejercer estos derechos sólo deberás llamar al 600 390 1000. Del mismo modo,
                  siempre puedes solicitar la suspensión de comunicaciones promocionales o
                  publicitarias, de conformidad a lo dispuesto en el artículo 28 B de la Ley
                  N°19.496 sobre Protección de los Derechos de los Consumidores.
                </p>
              </li>
            </ul>
            <p>
              <small>
                * Para efectos de estos Términos y Condiciones o Contrato, se entenderá por
                &quot;empresas relacionadas&quot; a todas las empresas filiales de la sociedad
                S.A.C.I. Falabella, controladas directa o indirectamente por ésta, o incluso
                aquella en que S.A.C.I. Falabella tenga o llegue a tener una participación
                societaria directa o indirecta equivalente o superior al 40% del capital social,
                tales como como Falabella, Sodimac, Tottus, Seguros Falabella, Banco Falabella,
                CMR, Viajes Falabella, CF Seguros, Rentas Falabella, Mall Plaza, Juan Valdéz e
                Imperial.
              </small>
            </p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default QuoteFormInformation;
