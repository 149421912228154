import { reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import SubscriptionFormFourthPage from '../components/SubscriptionFormFourthPage';
import validate from '../fixtures/validate';
import { getSubscriptionLoading } from '../actions/selectors';
import focusInputOnError from '../../commons/actions/focusInputOnError';

export const mapStateToProps = state => ({ loading: getSubscriptionLoading(state) });

export const mapDispatchToProps = dispatch => ({
  resetBeneficiaryPercentage: () =>
    dispatch(change('subscription', 'beneficiary-percentage-1', '100')),
});

const form = reduxForm({
  form: 'subscription',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: focusInputOnError,
})(SubscriptionFormFourthPage);

const connectedSubscriptionForm = connect(mapStateToProps, mapDispatchToProps)(form);

export default connectedSubscriptionForm;
