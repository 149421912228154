import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import { Field } from 'redux-form';
import { PropTypes } from 'prop-types';
import Select from '../../commons/select/Select';
import Loading from '../../commons/loading/Loading';
import Beneficiaries from '../containers/Beneficiaries';

export class BeneficiariesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiariesSelectIndex: 1,
      isHeir: true,
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'vpvBeneficiarios',
        },
        dataLayerName: 'PageSubProducts',
      };

      TagManager.dataLayer(tagManagerArgs);

      ReactGA.event('funnel_step_viewed', {
        user_id: `CL_RUT_${window.sessionStorage.getItem('hashID')}`,
        funnel_name: 'contratacion-seguro',
        product_name: 'web-vida-legado',
        funnel_step_name: 'paso-beneficiarios',
        assist_type: 'autoasistido',
      });
    }
  }

  handleHeirChange = ({ target: { value } }) => {
    this.setState({ isHeir: value === 'true' });
  };

  handleChange = ({ target: { value } }) => {
    const { resetBeneficiaryPercentage } = this.props;

    const select = parseInt(value, 10);

    if (select === 1) {
      resetBeneficiaryPercentage();
    }

    return this.setState({ beneficiariesSelectIndex: select });
  };

  submitForm = values => {
    const hasBeneficiaryPercentage100 = 'beneficiary-rut-1' in values;
    ReactGA.event('user_interaction', {
      action: !hasBeneficiaryPercentage100 ? 'herederos-legales' : 'beneficiarios',
      location: 'beneficiarios',
      flow: 'vida-con-ahorro',
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.handleSubmit();
  };

  render() {
    const { handleSubmit, previousPage, pristine, submitting, beneficiariesOptions, loading } =
      this.props;

    const { beneficiariesSelectIndex, isHeir } = this.state;

    return (
      <div className="col-12 col-md-10 wizard-card">
        {!loading ? (
          <form onSubmit={handleSubmit(this.submitForm)} noValidate>
            <div className="w-100 flex-wrap">
              <div className="col-12">
                <h2 className="wizard-card--title">
                  Beneficiarios
                  <div className="progress-step">
                    <div className="done" />
                    <div className="done" />
                    <div className="done" />
                    <div className="current" />
                  </div>
                </h2>
              </div>
              <div className="col-12">
                <div>
                  <label className="custom-check custom-check__radio mr20" htmlFor="no-quiero">
                    <div className="custom-check--container">
                      <Field
                        id="no-quiero"
                        name="heir"
                        component="input"
                        value="true"
                        type="radio"
                        onChange={this.handleHeirChange}
                        checked={isHeir === true}
                      />
                      <div className="custom-check--bg" />
                      <i />
                    </div>
                    <span>Quiero dejar a mis herederos legales</span>
                  </label>
                </div>
                <div>
                  <label className="custom-check custom-check__radio mr20" htmlFor="si-quiero">
                    <div className="custom-check--container">
                      <Field
                        id="si-quiero"
                        name="heir"
                        component="input"
                        value="false"
                        type="radio"
                        onChange={this.handleHeirChange}
                        checked={isHeir === false}
                      />
                      <div className="custom-check--bg" />
                      <i />
                    </div>
                    <span>Quiero elegir a mis beneficiarios</span>
                  </label>
                </div>
              </div>
              {!isHeir ? (
                <div>
                  <div className="col-12 mt40">
                    <p className="mb30">Puedes indicar hasta 7 personas.</p>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 form-group">
                    <label htmlFor="beneficiarios">Cantidad de Beneficiarios</label>
                    <Field
                      name="beneficiaries"
                      component={Select}
                      options={beneficiariesOptions}
                      placeholder="Selecciona..."
                      onChange={this.handleChange}
                      showDefault={false}
                    />
                  </div>
                  <div className="col-12" />
                  <div className="col-12">
                    <div className="row mt30">
                      <Beneficiaries index={beneficiariesSelectIndex} />
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="row justify-content-center mt30 buttons-container">
              <div className="col-12 col-sm-5 col-md-4">
                <button className="btn white back-to-dps" type="button" onClick={previousPage}>
                  Volver
                </button>
              </div>
              <div className="col-12 col-sm-5 col-md-4">
                <button
                  className="btn primary go-to-payment"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Continuar
                </button>
              </div>
            </div>
          </form>
        ) : (
          <div className="row">
            <div className="col-12">
              <h2 className="wizard-card--title">
                Beneficiarios
                <div className="progress-step">
                  <div className="done" />
                  <div className="done" />
                  <div className="done" />
                  <div className="current" />
                </div>
              </h2>
            </div>
            <Loading />
          </div>
        )}
      </div>
    );
  }
}

BeneficiariesView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  beneficiariesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  resetBeneficiaryPercentage: PropTypes.func.isRequired,
};

BeneficiariesView.defaultProps = {
  beneficiariesOptions: [
    {
      value: '1',
      name: '1 Beneficiario',
    },
    {
      value: '2',
      name: '2 Beneficiarios',
    },
    {
      value: '3',
      name: '3 Beneficiarios',
    },
    {
      value: '4',
      name: '4 Beneficiarios',
    },
    {
      value: '5',
      name: '5 Beneficiarios',
    },
    {
      value: '6',
      name: '6 Beneficiarios',
    },
    {
      value: '7',
      name: '7 Beneficiarios',
    },
  ],
};

export default BeneficiariesView;
