import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  formatPrice,
  formatCurrencyString,
  activePromo,
  activeCyber,
  getPlanNumber,
} from '../../commons/helpers';

class SubProduct extends Component {
  handleClick = () => {
    const { selectProduct, subProduct } = this.props;

    selectProduct(subProduct);
  };

  render() {
    const { subProduct, lineView } = this.props;

    return (
      <article className={`col-12 ${!lineView ? 'col-sm-12 col-md-6 col-lg-4' : ''}`}>
        <div className={`product-card ${!lineView ? '' : 'product-card--line'}`}>
          <header
            className={`${!lineView ? '' : 'd-none'} ${
              activePromo() ? 'mb10 bg-dark-gray text-white' : ''
            }`}
          >
            {activeCyber() ? (
              <img
                src="https://sfestaticos.blob.core.windows.net/guideline/cyber/2021/logo-cyber-matriz.svg"
                alt="Logo Cyber"
                height="35px"
              />
            ) : (
              <>
                Plan&nbsp;
                {getPlanNumber(subProduct.properties.id)}
              </>
            )}
          </header>
          <section className={!lineView ? '' : 'col-sm-4'}>
            <p
              className={`mt5 mb0 ${!lineView ? 'd-none' : ''} ${
                lineView && activePromo ? 'mt10' : ''
              }`}
            >
              Capital asegurado&nbsp;
              <b>{`${formatCurrencyString(subProduct.amount)} UF`}</b>
            </p>
            <figure>
              <img
                className={`${lineView && activePromo ? 'w-50' : ''}`}
                src="//sfestaticos.blob.core.windows.net/guideline/companies/metlife.svg"
                alt="Metlife"
              />
            </figure>
            <h3 className="product-card--price mb0">
              <span>
                <img
                  src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/money.svg"
                  alt="Simbolo Moneda"
                  height="40px"
                />
              </span>
              {formatPrice(subProduct.premiums.local)}
            </h3>
            <p>{`UF ${subProduct.premiums.monthly} mensuales`}</p>
            <hr className={!lineView ? '' : 'd-none'} />
            {activePromo() && <div className={!lineView ? 'mt20 w-100' : ''} />}
            <p className={lineView ? 'd-none' : 'mb20'}>
              Capital asegurado&nbsp;
              <b>{`${formatCurrencyString(subProduct.amount)} UF`}</b>
            </p>
            {activePromo() && <hr className={!lineView ? '' : 'd-none'} />}
          </section>

          <div className={!lineView ? 'col-12' : 'col-sm-6'}>
            {activePromo() && (
              <div className={`product-card--promos ${lineView ? 'mt20' : 'mb20'}`}>
                <div className="col-12 mx-auto">
                  <img
                    src="https://sfestaticos.blob.core.windows.net/guideline/promos/matriz-3-cuotas-gratis.svg"
                    alt="Promo"
                    width="50%"
                  />
                  <p className="mb0 px-14 mt10 mb0 line-height-normal">*mes 4, 7 y 10</p>
                </div>
              </div>
            )}
          </div>

          <footer className={!lineView ? '' : 'col-sm-4'}>
            <button
              onClick={this.handleClick}
              className="btn white btn--hover-green matrix-button"
              type="button"
            >
              Quiero contratar
            </button>
            <a
              className="benefits-assistance-details"
              href="//sfestaticos.blob.core.windows.net/pmc/vida/assets/pdf/propuesta-generica-vida-ahorro.pdf"
              target="blank"
            >
              Ver condiciones y coberturas
              <i className="material-icons">chevron_right</i>
            </a>
          </footer>
        </div>
      </article>
    );
  }
}

SubProduct.propTypes = {
  selectProduct: PropTypes.func.isRequired,
  subProduct: PropTypes.shape({
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    premiums: PropTypes.shape({
      annual: PropTypes.number.isRequired,
      local: PropTypes.number.isRequired,
      monthly: PropTypes.number.isRequired,
      period: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }).isRequired,
    properties: PropTypes.shape({
      benefits: PropTypes.string.isRequired,
      campaign: PropTypes.string,
      characteristics: PropTypes.string,
      deductible: PropTypes.number.isRequired,
      details: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
  lineView: PropTypes.bool,
};

SubProduct.defaultProps = {
  lineView: false,
};

export default SubProduct;
