import * as constants from './constants';

export const actionSubmitQuoteForm = payload => ({
  type: constants.SUBMIT_QUOTE_FORM,
  payload,
});

export const actionSetQuoteForm = payload => ({
  type: constants.SET_QUOTE_FORM,
  payload,
});

export const actionFetchQuotePlans = () => ({
  type: constants.FETCH_QUOTE_PLANS,
});

export const actionSetQuotePlans = payload => ({
  type: constants.SET_QUOTE_PLANS,
  payload,
});

export const actionSetQuoteResponse = payload => ({
  type: constants.SET_QUOTE_RESPONSE,
  payload,
});

export const actionSetSubProducts = payload => ({
  type: constants.SET_SUBPRODUCTS,
  payload,
});

export const actionSelectSubProduct = payload => ({
  type: constants.SELECT_SUBPRODUCT,
  payload,
});

export const actionSetSelectedSubProduct = payload => ({
  type: constants.SET_SELECTED_SUBPRODUCT,
  payload,
});

export const actionSetLoading = payload => ({
  type: constants.CHANGE_QUOTE_LOADING,
  payload,
});

export const actionSetPlanLoading = payload => ({
  type: constants.CHANGE_PLAN_LOADING,
  payload,
});

export const actionBackToQuote = () => ({
  type: constants.BACK_TO_QUOTE,
});

export const setUUID = payload => ({
  type: constants.SET_UUID,
  payload,
});

export const setClosingToken = payload => ({
  type: constants.SET_CLOSING_TOKEN,
  payload,
});
