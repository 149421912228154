import { rutValidator, exceededBirthday } from '../../commons/helpers';

const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,4}$/i;
const regexDate = /^((0?[1-9]|[12][0-9]|3[01])[- /.](0?[1-9]|1[012])[- /.](19|20)?[0-9]{2})*$/i;
const regexPhone = /^(9|2)?[0-9]{8}$/i;

const validate = values => {
  const errors = {};

  if (!values.rut) {
    errors.rut = 'Campo obligatorio';
  } else if (!rutValidator(values.rut)) {
    errors.rut = 'Rut inválido';
  }

  if (!values.birthdate) {
    errors.birthdate = 'Campo obligatorio';
  } else if (!regexDate.test(values.birthdate)) {
    errors.birthdate = 'Formato de fecha inválido, el formato es dd-mm-yyyy';
  } else if (!exceededBirthday(values.birthdate)) {
    errors.birthdate = 'Fecha de nacimiento debe ser menor a 60 años 364 días';
  }

  if (!values.email) {
    errors.email = 'Campo obligatorio';
  } else if (!regexEmail.test(values.email)) {
    errors.email = 'Correo inválido';
  }

  if (!values.phone) {
    errors.phone = 'Campo obligatorio';
  } else if (values.phone.trim().length < 9) {
    errors.phone = 'Teléfono inválido';
  } else if (!regexPhone.test(values.phone)) {
    errors.phone = 'Teléfono inválido';
  }

  if (!values.plan) {
    errors.plan = 'Campo obligatorio';
  }

  return errors;
};

export default validate;
