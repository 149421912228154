import { rutValidator } from '../../commons/helpers';

const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const regexDate = /^((0?[1-9]|[12][0-9]|3[01])[- /.](0?[1-9]|1[012])[- /.](19|20)?[0-9]{2})*$/i;
const regexNumber = /^[0-9]*$/i;
const regexPhone = /^(9|2)?[0-9]{8}$/i;
const regexString = /^[A-Za-zÀ-ÿÑñ\-' ]+$/i;
const regexAddress = /^[A-Za-zÀ-ÿÑñ0-9\-' ]+$/i;

const validate = values => {
  const errors = {};

  if (!values.rut) {
    errors.rut = 'Campo obligatorio';
  }

  if (!values.name) {
    errors.name = 'Campo obligatorio';
  } else if (!regexString.test(values.name)) {
    errors.name = 'El nombre no puede contener números ni caracteres especiales, solo letras';
  } else if (values.name.length > 50) {
    errors.name = 'El nombre solo puede contener un máximo de 50 caracteres';
  }

  if (!values.paternal) {
    errors.paternal = 'Campo obligatorio';
  } else if (!regexString.test(values.paternal)) {
    errors.paternal =
      'El apellido no puede contener números ni caracteres especiales, solo letras';
  } else if (values.paternal.length > 50) {
    errors.paternal = 'El apellido solo puede contener un máximo de 50 caracteres';
  }

  if (!values.maternal) {
    errors.maternal = 'Campo obligatorio';
  } else if (!regexString.test(values.maternal)) {
    errors.maternal =
      'El apellido no puede contener números ni caracteres especiales, solo letras';
  } else if (values.maternal.length > 50) {
    errors.maternal = 'El apellido solo puede contener un máximo de 50 caracteres';
  }

  if (!values.phone) {
    errors.phone = 'Campo obligatorio';
  } else if (values.phone.trim().length < 9) {
    errors.phone = 'Teléfono inválido';
  } else if (!regexPhone.test(values.phone)) {
    errors.phone = 'Teléfono inválido';
  }

  if (!values.email) {
    errors.email = 'Campo obligatorio';
  } else if (!regexEmail.test(values.email)) {
    errors.email = 'Correo inválido';
  }

  if (!values.birthdate) {
    errors.birthdate = 'Campo obligatorio';
  } else if (!regexDate.test(values.birthdate)) {
    errors.birthdate = 'Formato de fecha inválido, el formato es dd-mm-yyyy';
  }

  if (!values.address) {
    errors.address = 'Campo obligatorio';
  } else if (!regexAddress.test(values.address)) {
    errors.address =
      'La dirección no puede contener números ni caracteres especiales, solo letras y espacios';
  } else if (values.address.length > 130) {
    errors.address = 'La dirección solo puede contener un máximo de 130 caracteres';
  }

  if (!values.addressNumber) {
    errors.addressNumber = 'Campo obligatorio';
  } else if (!regexNumber.test(values.addressNumber)) {
    errors.addressNumber = 'Solo se debe ingresar números';
  }

  if (!values.region) {
    errors.region = 'Campo obligatorio';
  }

  if (!values.province) {
    errors.province = 'Campo obligatorio';
  }

  if (!values.commune) {
    errors.commune = 'Campo obligatorio';
  }

  if (!values.declaration) {
    errors.declaration = 'Debe confirmar su DPS';
  }

  if (!values.gender) {
    errors.gender = 'Debe seleccionar su género';
  }

  switch (values.payMethod) {
    case 'CMR':
      if (!values.cardNumber) {
        errors.cardNumber = 'Debe ingresar su tarjeta';
      } else if (values.cardNumber.trim().length < 19) {
        errors.cardNumber = 'Debe ingresar los 16 dígitos de su tarjeta';
      }
      if (!values.declarationCard) {
        errors.declarationCard =
          'Debe aceptar los términos y condiciones. También debe confirmar que usted es el asegurado y pagador';
      }
      break;
    case 'OTHERS':
      if (!values.creditNumber) {
        errors.creditNumber = 'Debe ingresar su tarjeta';
      } else if (values.creditNumber.trim().length < 19) {
        errors.creditNumber = 'Debe ingresar los 16 dígitos de su tarjeta';
      }
      if (!values.cardMonth) {
        errors.cardMonth = 'Debe ingresar mes de expiración de tarjeta';
      }
      if (!values.cardYear) {
        errors.cardYear = 'Debe ingresar año de expiración de tarjeta';
      }
      if (!values.declarationCard) {
        errors.declarationCard =
          'Debe aceptar los términos y condiciones. También debe confirmar que usted es el asegurado y pagador';
      }
      break;
    case 'MANDATE':
      if (!values.nameBank) {
        errors.nameBank = 'Debe seleccionar un banco';
      }
      if (!values.accountBank) {
        errors.accountBank = 'Debe ingresar un número de cuenta corriente';
      } else if (values.accountBank.trim().length < 6 || values.accountBank.trim().length > 16) {
        errors.accountBank = 'El número de cuenta corriente debe ser entre 6 y 16 caracteres';
      }
      if (!values.billingDay) {
        errors.billingDay = 'Debe ingresar un día de cobro';
      }
      break;
    default:
      break;
  }

  const totalBeneficiaries = values.beneficiaries ? values.beneficiaries : 1;
  let totalPercentage = 0;

  for (let i = 1; i <= totalBeneficiaries; i += 1) {
    if (!values[`beneficiary-birthdate-${i}`]) {
      errors[`beneficiary-birthdate-${i}`] = 'Campo Obligatorio';
    } else if (!regexDate.test(values[`beneficiary-birthdate-${i}`])) {
      errors[`beneficiary-birthdate-${i}`] = 'Formato de fecha inválido, el formato es dd-mm-yyyy';
    }

    if (!values[`beneficiary-maternal-${i}`]) {
      errors[`beneficiary-maternal-${i}`] = 'Campo Obligatorio';
    } else if (!regexString.test(values[`beneficiary-maternal-${i}`])) {
      errors[`beneficiary-maternal-${i}`] =
        'El apellido no puede contener números ni caracteres especiales, solo letras';
    } else if (values[`beneficiary-maternal-${i}`].length > 50) {
      errors[`beneficiary-maternal-${i}`] =
        'El apellido solo puede contener un máximo de 50 caracteres';
    }

    if (!values[`beneficiary-name-${i}`]) {
      errors[`beneficiary-name-${i}`] = 'Campo Obligatorio';
    } else if (!regexString.test(values[`beneficiary-name-${i}`])) {
      errors[`beneficiary-name-${i}`] =
        'El nombre no puede contener números ni caracteres especiales, solo letras';
    } else if (values[`beneficiary-name-${i}`].length > 50) {
      errors[`beneficiary-name-${i}`] = 'El nombre solo puede contener un máximo de 50 caracteres';
    }

    if (!values[`beneficiary-paternal-${i}`]) {
      errors[`beneficiary-paternal-${i}`] = 'Campo Obligatorio';
    } else if (!regexString.test(values[`beneficiary-paternal-${i}`])) {
      errors[`beneficiary-paternal-${i}`] =
        'El apellido no puede contener números ni caracteres especiales, solo letras';
    } else if (values[`beneficiary-paternal-${i}`].length > 50) {
      errors[`beneficiary-paternal-${i}`] =
        'El apellido solo puede contener un máximo de 50 caracteres';
    }

    if (!values[`beneficiary-percentage-${i}`]) {
      errors[`beneficiary-percentage-${i}`] = 'Campo Obligatorio';
    } else {
      totalPercentage += parseFloat(values[`beneficiary-percentage-${i}`]);
    }

    if (!values[`beneficiary-relationship-${i}`]) {
      errors[`beneficiary-relationship-${i}`] = 'Campo Obligatorio';
    }

    if (!values[`beneficiary-rut-${i}`]) {
      errors[`beneficiary-rut-${i}`] = 'Campo Obligatorio';
    } else if (!rutValidator(values[`beneficiary-rut-${i}`])) {
      errors[`beneficiary-rut-${i}`] = 'Rut inválido';
    }
  }

  if (totalPercentage !== 100) {
    for (let i = 1; i <= totalBeneficiaries; i += 1) {
      if (!errors[`beneficiary-percentage-${i}`]) {
        errors[`beneficiary-percentage-${i}`] =
          'Suma de los porcentajes debe ser siempre igual a 100';
      }
    }
  }

  return errors;
};

export default validate;
