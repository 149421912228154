import React from 'react';
import { PropTypes } from 'prop-types';

const banks = {
  bbva: {
    name: 'Banco BBVA',
    fileName: 'bbva',
  },
  estado: {
    name: 'Banco Estado',
    fileName: 'estado',
  },
  falabella: {
    name: 'Banco Falabella',
    fileName: 'falabella',
  },
  santander: {
    name: 'Banco Santander',
    fileName: 'santander',
  },
  bci: {
    name: 'BCI',
    fileName: 'bci',
  },
  chile: {
    name: 'Banco de Chile / Banco Edwards',
    fileName: 'banco-chile',
  },
  scotiabank: {
    name: 'Scotiabank',
    fileName: 'scotiabank',
  },
};

const manualMandateLink = nameBank =>
  `${process.env.REACT_APP_MANUAL_BASE_URL}/full-cobertura/pdf/instructivo-activacion-pac-${banks[nameBank].fileName}.pdf`;

const MandatePACSuccess = ({ mandateId, bankName }) => (
  <>
    <div className="col-12 text-center message--title border-top mt30 pt30">
      <h1 className="px-28">Suscribe tu PAC</h1>
    </div>
    <div className="col-12 col-sm-10 mx-auto">
      <div className="row">
        <div className="col-12 col-sm-3 col-md-2 message--icon-container">
          <div className="message--icon line-height-55">
            <img
              src="//sfestaticos.blob.core.windows.net/pmc/img/success/bank.svg"
              alt="Icono Banco"
            />
          </div>
        </div>
        <div className="col-12 col-sm-9 col-md-10">
          <p className="px-20">
            Debes suscribir tu PAC en&nbsp;
            {banks[bankName].name}
            &nbsp;con este número de cliente:
          </p>
          <p className="text-primary text-bold px-36">{mandateId}</p>
          <p>
            Recibirás un correo con las instrucciones para la suscripción del PAC o puedes ver el
            instructivo&nbsp;
            <a href={manualMandateLink(bankName)} target="_blank" rel="noopener noreferrer">
              aquí
            </a>
          </p>
          <p>
            Te recomendamos no establecer un monto tope (por las variaciones de UF)
            <br />
            *Nunca te cobraremos más del valor contratado
          </p>
        </div>
      </div>
    </div>
  </>
);

MandatePACSuccess.propTypes = {
  mandateId: PropTypes.string.isRequired,
  bankName: PropTypes.string.isRequired,
};

export default MandatePACSuccess;
