import React from 'react';
import { Field } from 'redux-form';
import { diseases } from '../fixtures/data';

const DpsFormMobile = () => (
  <div className="col-12">
    {diseases.map(item => (
      <div className="row mb20 shadow" key={item.name}>
        <div className="col-12 pb5 pt5 pl20 pr20 bg-green text-white">{item.name}</div>
        <div className="col-6 pt10 pb10 text-center">
          <p className="mb0">¿Tiene o se encuentra en tratamiento?</p>
        </div>
        <div className="col-6 pt10 pb10 text-center align-items-center justify-content-center d-flex">
          <p className="mb0">¿Tuve?</p>
        </div>
        <div className="col-6 pt10 pb10 text-center border-bottom">
          <label
            className="custom-check custom-check__radio mr20"
            htmlFor={`no-tengo-${item.have}`}
          >
            <div className="custom-check--container">
              <Field
                id={`no-tengo-${item.have}`}
                name={item.have}
                component="input"
                value="0"
                type="radio"
              />
              <div className="custom-check--bg" />
              <i />
            </div>
            <span>No</span>
          </label>

          <label
            className="custom-check custom-check__radio mr20"
            htmlFor={`si-tengo-${item.have}`}
          >
            <div className="custom-check--container">
              <Field
                id={`si-tengo-${item.have}`}
                name={item.have}
                component="input"
                value="1"
                type="radio"
              />
              <div className="custom-check--bg" />
              <i />
            </div>
            <span>Si</span>
          </label>
        </div>
        <div className="col-6 pt10 pb10 text-center border-bottom">
          <label className="custom-check custom-check__radio mr20" htmlFor={`no-tuve-${item.had}`}>
            <div className="custom-check--container">
              <Field
                id={`no-tuve-${item.had}`}
                name={item.had}
                component="input"
                value="0"
                type="radio"
              />
              <div className="custom-check--bg" />
              <i />
            </div>
            <span>No</span>
          </label>
          <label className="custom-check custom-check__radio mr20" htmlFor={`si-tuve-${item.had}`}>
            <div className="custom-check--container">
              <Field
                id={`si-tuve-${item.had}`}
                name={item.had}
                component="input"
                value="1"
                type="radio"
              />
              <div className="custom-check--bg" />
              <i />
            </div>
            <span>Si</span>
          </label>
        </div>
      </div>
    ))}
  </div>
);

export default DpsFormMobile;
