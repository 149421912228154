import React from 'react';
import { PropTypes } from 'prop-types';
import ConnectedQuoteForm from '../containers/QuoteForm';

function Quote(props) {
  const { submitQuoteForm, loading } = props;
  return <ConnectedQuoteForm onSubmit={submitQuoteForm} loading={loading} />;
}

Quote.propTypes = {
  submitQuoteForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Quote;
