import * as constants from './constants';

export const actionSubmitSubscriptionForm = payload => ({
  type: constants.SUBMIT_SUBSCRIPTION_FORM,
  payload,
});

export const actionSetSubscriptionForm = payload => ({
  type: constants.SET_SUBSCRIPTION_FORM,
  payload,
});

export const actionSetProposal = payload => ({
  type: constants.SET_PROPOSAL,
  payload,
});

export const actionSetPaymentType = payload => ({
  type: constants.SET_PAYMENT_TYPE,
  payload,
});

export const actionBackToSubProducts = () => ({
  type: constants.BACK_TO_SUBPRODUCTS,
});

export const actionChangeBeneficiariesSelect = payload => ({
  type: constants.CHANGE_BENEFICIARIES_SELECT,
  payload,
});

export const actionSetLoading = payload => ({
  type: constants.CHANGE_SUBSCRIPTION_LOADING,
  payload,
});
