export default [
  {
    name: 'Arica y Parinacota',
    ordinal: 'XV',
    code: 'CL-AP',
  },
  {
    name: 'Tarapacá',
    ordinal: 'I',
    code: 'CL-TA',
  },
  {
    name: 'Antofagasta',
    ordinal: 'II',
    code: 'CL-AN',
  },
  {
    name: 'Atacama',
    ordinal: 'III',
    code: 'CL-AT',
  },
  {
    name: 'Coquimbo',
    ordinal: 'IV',
    code: 'CL-CO',
  },
  {
    name: 'Valparaíso',
    ordinal: 'V',
    code: 'CL-VS',
  },
  {
    name: 'Libertador Gral. Bernardo O"Higgins',
    ordinal: 'VI',
    code: 'CL-LI',
  },
  {
    name: 'Maule',
    ordinal: 'VII',
    code: 'CL-ML',
  },
  {
    name: 'Biobío',
    ordinal: 'VIII',
    code: 'CL-BI',
  },
  {
    name: 'Araucanía',
    ordinal: 'IX',
    code: 'CL-AR',
  },
  {
    name: 'Los Ríos',
    ordinal: 'XIV',
    code: 'CL-LR',
  },
  {
    name: 'Los Lagos',
    ordinal: 'X',
    code: 'CL-LL',
  },
  {
    name: 'Aisén del Gral. Carlos Ibañez del Campo',
    ordinal: 'XI',
    code: 'CL-AI',
  },
  {
    name: 'Magallanes y de la Antártica Chilena',
    ordinal: 'XII',
    code: 'CL-MA',
  },
  {
    name: 'Metropolitana de Santiago',
    ordinal: 'XIII',
    code: 'CL-RM',
  },
];
