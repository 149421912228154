import { connect } from 'react-redux';
import SuccessComponent from '../components/Success';
import { getProposal, getSubscriptionForm } from '../../subscription/actions/selectors';
import { getSelectedSubProduct } from '../../quote/actions/selectors';
import { initialState } from '../../commons/actions/creators';

export const mapStateToProps = state => ({
  proposal: getProposal(state),
  subscriptionData: getSubscriptionForm(state),
  subProduct: getSelectedSubProduct(state),
});

export const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(initialState()),
});

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SuccessComponent);

export default connectedComponent;
