import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SubscriptionFormFirstPage from '../components/SubscriptionFormFirstPage';
import validate from '../fixtures/validate';
import { getQuoteForm } from '../../quote/actions/selectors';
import { actionBackToSubProducts } from '../actions/creators';
import focusInputOnError from '../../commons/actions/focusInputOnError';
import { defaultDiseasesValues, defaultPayMethod } from '../fixtures/data';

export const mapStateToProps = state => ({
  initialValues: {
    ...getQuoteForm(state),
    ...defaultDiseasesValues,
    payMethod: defaultPayMethod,
    'beneficiary-percentage-1': '100',
    declarationCard: false,
    heir: true,
  },
});

export const mapDispatchToProps = dispatch => ({
  backToSubProducts: () => dispatch(actionBackToSubProducts()),
});

const form = reduxForm({
  form: 'subscription',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: focusInputOnError,
})(SubscriptionFormFirstPage);

const connectedSubscriptionForm = connect(mapStateToProps, mapDispatchToProps)(form);

export default connectedSubscriptionForm;
