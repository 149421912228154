import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import PropTypes from 'prop-types';
import Container from '../../layout/Container';
import SubProduct from '../containers/SubProduct';
import Logo from '../../assets/svg/logo-vida-filled.svg';
import './assets/subproducts.css';

export class SubProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lineView: false,
    };
  }

  gridOff = () =>
    this.setState({
      lineView: false,
    });

  gridOn = () =>
    this.setState({
      lineView: true,
    });

  render() {
    const { lineView } = this.state;

    const { subProducts, clientInfo, backToQuote } = this.props;
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'vpv',
        },
        dataLayerName: 'PageSubProducts',
      };

      TagManager.dataLayer(tagManagerArgs);

      ReactGA.event('funnel_step_viewed', {
        user_id: `CL_RUT_${window.sessionStorage.getItem('hashID')}`,
        funnel_name: 'contratacion-seguro',
        product_name: 'web-vida-legado',
        funnel_step_name: 'paso-cotizacion',
        assist_type: 'autoasistido',
      });
    }

    return (
      <Container showNavbar={false}>
        <section className="container-fluid bg-green client-info-bar">
          <div className="container">
            <div className="row">
              <div className="col-12 text-white pt10 pb10">
                {clientInfo.rut && (
                  <p className="mt0 mb0 px-14 d-block d-md-inline-block">
                    <span>Rut cliente: </span>
                    <span className="text-light">{clientInfo.rut}</span>
                    <span className="ml5 mr5">|</span>
                    <span>Correo: </span>
                    <span className="text-light">{clientInfo.email}</span>
                  </p>
                )}
                <div className="float-right text-right d-none d-md-inline-block">
                  <p className="mb0 px-12 d-inline-block mr10 line-height-normal">
                    Tipo de vista:
                  </p>
                  <ul className="list-unstyled line-height-none mb0 float-right">
                    <li className="list-inline-item">
                      <button
                        className={`link change-view-grid ${
                          lineView ? 'text-white' : 'text-dark-gray'
                        }`}
                        type="button"
                        onClick={this.gridOff}
                      >
                        <i className="material-icons px-20 events-none">grid_on</i>
                      </button>
                    </li>
                    <li className="list-inline-item">
                      <button
                        className={`link change-view-line ${
                          !lineView ? 'text-white' : 'text-dark-gray'
                        }`}
                        type="button"
                        onClick={this.gridOn}
                      >
                        <i className="material-icons px-22 events-none">reorder</i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container mb20">
          <div className="row">
            <div className="col-12">
              {subProducts.length > 0 && (
                <h1 className="text-center mt60 mb50">Estas son las mejores opciones para ti</h1>
              )}
              {subProducts.length < 1 && (
                <div>
                  <h2 className="text-center mt60 mb50">
                    Lamentablemente no puedes avanzar en la contratación, es posible que ya cuentes
                    con este producto o no cumplas con la edad de contratación
                  </h2>
                  <h3 className="text-center">
                    Para cualquier consulta, no dudes en llamar al Centro de Atención al Cliente de
                    Seguros Falabella Corredores, al teléfono 600 390 1000 ó al 02 2390 6570
                  </h3>
                </div>
              )}
              <div className="row mt50 justify-content-center">
                {subProducts.map(subproduct => (
                  <SubProduct
                    key={subproduct.properties.id}
                    id={subproduct.properties.id}
                    lineView={lineView}
                  />
                ))}
                {!subProducts.length && (
                  <div className="col-12 col-sm-8 col-md-5 mx-auto">
                    <button
                      className="btn primary quote-fail-retry"
                      type="button"
                      onClick={backToQuote}
                    >
                      Volver a cotizar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <section className="container-fluid bg-light-gray mt100">
          <div className="container text-center">
            <div className="row">
              <div className="col-12 relative">
                <img className="logo-vida-filled" src={Logo} alt="Logo Vida" width="134" />
              </div>
              <div className="col-12">
                <h2 className="h1 mt100 mb90">Beneficios</h2>
              </div>
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-3 beneficios-box">
                    <figure>
                      <div className="beneficios-box--image">
                        <img
                          src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/ahorro.svg"
                          alt="Alcancía ahorro"
                        />
                      </div>
                      <figcaption>
                        <p>
                          Con este seguro destinas el 50% de lo que pagas cada mes a un fondo de
                          ahorro y a partir del segundo año aumenta a un 60%, con una rentabilidad
                          asegurada del 1% anual.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="col-12 col-sm-3 beneficios-box">
                    <figure>
                      <div className="beneficios-box--image">
                        <img
                          src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/familia.svg"
                          alt="Familia"
                        />
                      </div>
                      <figcaption>
                        <p>
                          Libre designación de beneficiarios y sus porcentajes de participación. En
                          caso de fallecimiento, se paga a los beneficiarios el capital asegurado
                          según el plan, más el monto ahorrado por el asegurado.
                        </p>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="col-12 col-sm-3 beneficios-box">
                    <figure>
                      <div className="beneficios-box--image">
                        <img
                          src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/descuento.svg"
                          alt="Descuento"
                        />
                      </div>
                      <figcaption>
                        <p>Hasta 20% de descuento en Ópticas Place Vendôme.</p>
                        <p>Precios preferentes en red dental de clínicas asociadas a Metlife.</p>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    );
  }
}

SubProducts.propTypes = {
  subProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  clientInfo: PropTypes.shape({
    rut: PropTypes.string,
    birthdate: PropTypes.string,
    plan: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  backToQuote: PropTypes.func,
};

SubProducts.defaultProps = {
  subProducts: [],
  clientInfo: {},
  backToQuote: () => {},
};

export default SubProducts;
