import React from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';

const Select = ({
  options,
  input,
  className,
  placeholder,
  isDisabled,
  showDefault,
  loading,
  meta: { touched, error },
}) => (
  <div>
    <div className="select-container">
      <select
        id={input.name}
        className={loading ? 'form-control loading' : className}
        {...input}
        placeholder={placeholder}
        disabled={isDisabled || loading}
      >
        {!loading ? (
          showDefault && <option value="">{placeholder}</option>
        ) : (
          <option value="0" selected>
            Cargando...
          </option>
        )}
        {options.map(option => (
          <option key={option.name} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {loading ? <i className="element-spinner" /> : ''}
    </div>
    {touched && error && <small className="invalid">{error}</small>}
  </div>
);

Select.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  showDefault: PropTypes.bool,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
};

Select.defaultProps = {
  className: 'form-control',
  placeholder: 'Seleccione...',
  showDefault: true,
  isDisabled: false,
  loading: false,
};

export default Select;
