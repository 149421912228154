export const SUBMIT_QUOTE_FORM = 'SUBMIT_QUOTE_FORM';
export const SET_QUOTE_FORM = 'SET_QUOTE_FORM';
export const FETCH_QUOTE_PLANS = 'FETCH_QUOTE_PLANS';
export const SET_QUOTE_PLANS = 'SET_QUOTE_PLANS';
export const SELECT_SUBPRODUCT = 'SELECT_SUBPRODUCT';
export const SET_SELECTED_SUBPRODUCT = 'SET_SELECTED_SUBPRODUCT';
export const SET_SUBPRODUCTS = 'SET_SUBPRODUCTS';
export const SET_QUOTE_RESPONSE = 'SET_QUOTE_RESPONSE';
export const CHANGE_QUOTE_LOADING = 'CHANGE_QUOTE_LOADING';
export const CHANGE_PLAN_LOADING = 'CHANGE_PLAN_LOADING';
export const BACK_TO_QUOTE = 'BACK_TO_QUOTE';
export const SET_UUID = 'SET_UUID';
export const SET_CLOSING_TOKEN = 'SET_CLOSING_TOKEN';
