export const getQuoteForm = state => state.quote.quoteForm;

export const getQuotePlans = state => state.quote.quotePlans;

export const getQuoteResponse = state => state.quote.quoteResponse;

export const getQuoteLoading = state => state.quote.loading;

export const getPlanLoading = state => state.quote.loadingPlans;

export const getSubProduct = (state, props) =>
  state.quote.subProducts.find(subProduct => subProduct.properties.id === props.id);

export const getSelectedSubProduct = state => state.quote.selectedSubProduct;

export const getSubProducts = state => state.quote.subProducts;

export const getUUID = state => state.quote.uuid;

export const getClosingToken = state => state.quote.closingToken;
