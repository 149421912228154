import React from 'react';
import { Link } from 'react-router-dom';
import './assets/not-found.css';

const NotFound = () => (
  <div className="error-404">
    <section className="container">
      <div className="row">
        <article className="col-10 offset-1 offset-sm-0 col-sm-8 col-md-6 col-lg-4 br-15 bg-white-alpha pl0 pr0 mt40">
          <header className="w-100 pl30 pr30 text-center bg-white pt20 pb20">
            <img
              src="https://sfestaticos.blob.core.windows.net/portal/imgs/logo/logo_cl.svg"
              alt="Logo Seguros Falabella"
              width="200px"
            />
          </header>
          <div className="w-100 pl30 pr30 pt20 pb20">
            <h1>No encontramos esta página</h1>
            <p>
              Es posible que la página que buscas no exista, haya cambiado de nombre o este
              temporalmente fuera de servicio.
            </p>
            <p>¡Tranquilo! puedes probar visitando nuestra página principal:</p>
          </div>
          <footer className="w-100 pl30 pr30">
            <Link className="btn primary px-16 mb20" to="/">
              Ir al inicio
            </Link>
          </footer>
        </article>
      </div>
    </section>
  </div>
);

export default NotFound;
