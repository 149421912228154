import React from 'react';
import './assets/feria.css';
import { feriaPromo } from '../../commons/helpers';

export const Feria = () => (
  <div className="container-fluid bg-light-gray pt30 pb100 mt50">
    <div className="container">
      <div className="row">
        <div className="col-12 mb30">
          <h2 className="text-primary px-24">También te puede interesar</h2>
          <p className="px-16">Aprovecha y cotiza alguno de nuestros seguros</p>
        </div>

        {/* Caja */}
        <div className="col-12 col-sm-6 col-md-4 mb20">
          <div className="w-100">
            <article className="col-12 br15 o-hidden shadow">
              <div className="row">
                <header
                  className={`col-12 pt10 pb10 relative ${
                    feriaPromo() ? 'bg-color-cyber' : 'bg-green'
                  }`}
                >
                  <h4 className="mb0 text-white sf text-light line-height-140p">
                    Seguro&nbsp;
                    <br />
                    &nbsp;
                    <span className="text-bold">Auto Full Cobertura</span>
                  </h4>
                  {feriaPromo() && (
                    <img
                      className="logo-cyber-feria"
                      src="//sfestaticos.blob.core.windows.net/guideline/cyber/2019/mayo/ico-cyber.svg"
                      alt="Logo Cyber"
                    />
                  )}
                </header>
                <figure className="w-100 mb0">
                  {!feriaPromo() && (
                    <img
                      className="img-fluid w-100"
                      src="//sfestaticos.blob.core.windows.net/feria/assets/img/genericos/auto.jpg"
                      alt="Seguro"
                    />
                  )}

                  {/* Promo */}
                  {feriaPromo() && (
                    <img
                      className="img-fluid w-100"
                      src="//sfestaticos.blob.core.windows.net/feria/assets/img/auto.png"
                      alt="Seguro"
                    />
                  )}
                  {/* Promo */}
                </figure>
                <footer className="w-100 text-center bg-white pt15 pb15">
                  <a
                    className="btn white feria-a-auto btn--hover-green feria-call-to-action"
                    href="//auto.segurosfalabella.com"
                  >
                    Contratar
                  </a>
                </footer>
              </div>
            </article>
          </div>
        </div>
        {/* fin Caja */}

        {/* Caja */}
        <div className="col-12 col-sm-6 col-md-4 mb20">
          <div className="w-100">
            <article className="col-12 br15 o-hidden shadow">
              <div className="row">
                <header
                  className={`col-12 pt10 pb10 relative ${
                    feriaPromo() ? 'bg-color-cyber' : 'bg-green'
                  }`}
                >
                  <h4 className="mb0 text-white sf text-light line-height-140p">
                    Seguro&nbsp;
                    <br />
                    &nbsp;
                    <span className="text-bold">de Viajes</span>
                  </h4>
                  {feriaPromo() && (
                    <img
                      className="logo-cyber-feria"
                      src="//sfestaticos.blob.core.windows.net/guideline/cyber/2019/mayo/ico-cyber.svg"
                      alt="Logo Cyber"
                    />
                  )}
                </header>
                <figure className="w-100 mb0">
                  {!feriaPromo() && (
                    <img
                      className="img-fluid w-100"
                      src="//sfestaticos.blob.core.windows.net/feria/assets/img/genericos/viajes.jpg"
                      alt="Seguro"
                    />
                  )}

                  {/* Promo */}
                  {feriaPromo() && (
                    <img
                      className="img-fluid w-100"
                      src="//sfestaticos.blob.core.windows.net/feria/assets/img/viajes.png"
                      alt="Seguro"
                    />
                  )}
                  {/* Promo */}
                </figure>
                <footer className="w-100 text-center bg-white pt15 pb15">
                  <a
                    className="btn white feria-a-viajes btn--hover-green feria-call-to-action"
                    href="//viajes.segurosfalabella.com"
                  >
                    Contratar
                  </a>
                </footer>
              </div>
            </article>
          </div>
        </div>
        {/* fin Caja */}

        {/* Caja */}
        <div className="col-12 col-sm-6 col-md-4 mb20">
          <div className="w-100">
            <article className="col-12 br15 o-hidden shadow">
              <div className="row">
                <header
                  className={`col-12 pt10 pb10 relative ${
                    feriaPromo() ? 'bg-color-cyber' : 'bg-green'
                  }`}
                >
                  <h4 className="mb0 text-white sf text-light line-height-140p">
                    Seguro&nbsp;
                    <br />
                    &nbsp;
                    <span className="text-bold">Complementario de Salud</span>
                  </h4>
                  {feriaPromo() && (
                    <img
                      className="logo-cyber-feria"
                      src="//sfestaticos.blob.core.windows.net/guideline/cyber/2019/mayo/ico-cyber.svg"
                      alt="Logo Cyber"
                    />
                  )}
                </header>
                <figure className="w-100 mb0">
                  {!feriaPromo() && (
                    <img
                      className="img-fluid w-100"
                      src="//sfestaticos.blob.core.windows.net/feria/assets/img/genericos/salud.jpg"
                      alt="Seguro"
                    />
                  )}

                  {/* Promo */}
                  {feriaPromo() && (
                    <img
                      className="img-fluid w-100"
                      src="//sfestaticos.blob.core.windows.net/feria/assets/img/salud.png"
                      alt="Seguro"
                    />
                  )}
                  {/* Promo */}
                </figure>
                <footer className="w-100 text-center bg-white pt15 pb15">
                  <a
                    className="btn white feria-a-salud btn--hover-green feria-call-to-action"
                    href="//salud.segurosfalabella.com"
                  >
                    Contratar
                  </a>
                </footer>
              </div>
            </article>
          </div>
        </div>
        {/* fin Caja */}
      </div>
    </div>
  </div>
);

export default Feria;
