import React from 'react';
import Container from '../../layout/Container';
import SubscriptionForm from '../containers/SubscriptionForm';

const Subscription = () => (
  <Container>
    <SubscriptionForm />
  </Container>
);

export default Subscription;
