import React from 'react';
import { PropTypes } from 'prop-types';
import NavbarComponent from '../navbar/Navbar';

function Header(props) {
  const { showNavbar } = props;

  return (
    <header className="container-fluid header">
      <div className="row">
        <div className="col-12 col-md-3 justify-content-center align-items-center header--inner d-flex">
          <a href="//www.segurosfalabella.com/cl/" target="_blank" rel="noopener noreferrer">
            <img
              src="https://sfestaticos.blob.core.windows.net/portal/imgs/logo/logo_cl.svg"
              alt="Logo Seguros Falabella"
              width="200px"
            />
          </a>
        </div>
        {showNavbar && <NavbarComponent />}
      </div>
    </header>
  );
}

Header.propTypes = {
  showNavbar: PropTypes.bool,
};

Header.defaultProps = {
  showNavbar: true,
};

export default Header;
