import { combineReducers } from 'redux';
import * as constants from '../actions/constants';

const initialQuoteForm = {
  rut: '',
  email: '',
  phone: '',
  birthdate: '',
  plan: '',
};

const quoteForm = (state = initialQuoteForm, action) => {
  switch (action.type) {
    case constants.SET_QUOTE_FORM:
      return action.payload;
    default:
      return state;
  }
};

const initialQuotePlans = [];

const quotePlans = (state = initialQuotePlans, action) => {
  switch (action.type) {
    case constants.SET_QUOTE_PLANS:
      return action.payload;
    default:
      return state;
  }
};

const initialStateSubProducts = [];

const subProducts = (state = initialStateSubProducts, action) => {
  switch (action.type) {
    case constants.SET_SUBPRODUCTS:
      return action.payload;
    default:
      return state;
  }
};

const initialStateQuoteResponse = {};

const quoteResponse = (state = initialStateQuoteResponse, action) => {
  switch (action.type) {
    case constants.SET_QUOTE_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

const initialStateSelectedSubProduct = {};

const selectedSubProduct = (state = initialStateSelectedSubProduct, action) => {
  switch (action.type) {
    case constants.SET_SELECTED_SUBPRODUCT:
      return action.payload;
    default:
      return state;
  }
};

const initialQuoteLoading = false;

const loading = (state = initialQuoteLoading, action) => {
  switch (action.type) {
    case constants.CHANGE_QUOTE_LOADING:
      return action.payload;
    default:
      return state;
  }
};

const initialPlanLoading = false;

const loadingPlans = (state = initialPlanLoading, action) => {
  switch (action.type) {
    case constants.CHANGE_PLAN_LOADING:
      return action.payload;
    default:
      return state;
  }
};

const initialUUID = '';
const initialClosingToken = '';

const uuid = (state = initialUUID, action) => {
  switch (action.type) {
    case constants.SET_UUID:
      return action.payload;
    default:
      return state;
  }
};

const closingToken = (state = initialClosingToken, action) => {
  switch (action.type) {
    case constants.SET_CLOSING_TOKEN:
      return action.payload;
    default:
      return state;
  }
};

const reducers = combineReducers({
  quoteForm,
  quotePlans,
  subProducts,
  selectedSubProduct,
  quoteResponse,
  loading,
  loadingPlans,
  uuid,
  closingToken,
});

export default reducers;
