import moment from 'moment-timezone';
import crypto from 'crypto';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';

moment.tz.setDefault('America/Santiago');

const regexRut = /^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/i;

const formatPrice = price => price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');

const formatDateToIsoString = (date, format) => moment(date, format).toISOString();

const cleanRut = rut =>
  typeof rut === 'string' ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase() : '';

const rutFormatter = rut => {
  let result =
    rut.length > 1
      ? `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`
      : `${rut.slice(-4, -1)}${rut.substr(rut.length - 1)}`;

  for (let i = 4; i < rut.length; i += 3) {
    result = `${rut.slice(-3 - i, -i)}.${result}`;
  }

  return result;
};

const formatRut = (rut, prev) => {
  if (rut.length > 12) {
    return prev;
  }

  const parsedRut = cleanRut(rut);
  const formatted = rutFormatter(parsedRut);

  return formatted;
};

const rutValidator = rut => {
  if (typeof rut !== 'string') {
    return false;
  }

  if (!regexRut.test(rut)) {
    return false;
  }

  const cleanedRut = cleanRut(rut);

  let t = parseInt(cleanedRut.slice(0, -1), 10);
  let m = 0;
  let s = 1;

  while (t > 0) {
    s = (s + (t % 10) * (9 - (m % 6))) % 11;
    t = Math.floor(t / 10);
    m += 1;
  }

  const v = s > 0 ? `${s - 1}` : 'K';

  return v === rut.slice(-1);
};

const parserRut = rut => rut.replace(/\./g, '').toLowerCase();

const parseStringToNumber = value => parseInt(value, 10) || null;

const obfuscateCardNumber = cardNumber => {
  const cardNumberWithoutSeparator = cardNumber.replace(/-/g, '');
  const firstNumbers = cardNumberWithoutSeparator.substring(0, 6);
  const lastNumbers = cardNumberWithoutSeparator.substring(12, cardNumberWithoutSeparator.length);
  const ofuscatedCardNumber = `${firstNumbers}000000${lastNumbers}`;
  return ofuscatedCardNumber;
};

const encryptCardNumber = cardNumber => {
  const buffer = Buffer.from(cardNumber.split(' ').join(''), 'utf8');
  const publicKey = `-----BEGIN PUBLIC KEY-----\n${process.env.REACT_APP_PUBLIC_CERT}\n-----END PUBLIC KEY-----`;
  const encrypted = crypto.publicEncrypt(publicKey, buffer);
  return encrypted.toString('base64');
};

const calculateUfAmountFromPlan = planId =>
  planId === 'VML7VI' ? '450' : (parseInt(planId.substring(3, 4), 10) * 500).toString();

const formatCurrencyString = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

const exceededBirthday = birthday => {
  const currentDate = moment();
  const birthdayToDate = moment(birthday, 'DD-MM-YYYY');
  const years = currentDate.diff(birthdayToDate, 'year');
  birthdayToDate.add(years, 'years');
  const days = currentDate.diff(birthdayToDate, 'days');
  return years <= 60 && days <= 364;
};

export {
  formatPrice,
  formatDateToIsoString,
  formatRut,
  parserRut,
  cleanRut,
  rutValidator,
  parseStringToNumber,
  obfuscateCardNumber,
  encryptCardNumber,
  calculateUfAmountFromPlan,
  formatCurrencyString,
  exceededBirthday,
};

export const activePromo = () => {
  const datePromoStart = new Date('2022-09-02T18:00:00.000Z');
  const datePromoEnd = new Date('2022-09-05T04:00:00.000Z');
  const actualDate = new Date();

  return datePromoStart <= actualDate && datePromoEnd > actualDate;
};

export const activeCyber = () => {
  const datePromoStart = new Date('2021-11-25T00:00:00.000Z');
  const datePromoEnd = new Date('2021-11-29T03:00:00.000Z');
  const actualDate = new Date();

  return datePromoStart <= actualDate && datePromoEnd > actualDate;
};

export const feriaPromo = () => {
  const dateStart = new Date('2019-10-07T02:30:00.000Z');
  const dateEnd = new Date('2019-10-10T03:00:00.000Z');
  const actualDate = new Date();

  return dateStart <= actualDate && dateEnd > actualDate;
};

export const hashData = userData => {
  const encryptedUserId = crypto.createHash('sha256').update(userData, 'utf8').digest('hex');

  return encryptedUserId;
};

export const getParameterFromURL = parameterName => {
  const url = window.location.href;

  if (parameterName === undefined) {
    return false;
  }

  const param = parameterName.replace(/[[\]]/g, '\\$&');
  const regularExpression = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`);

  const results = regularExpression.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getPlanNumber = nemo => nemo.substring(3, 4);

export const generateUUID = () => uuidv4();
