import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { PropTypes } from 'prop-types';
import { Field } from 'redux-form';
import ReactGA from 'react-ga4';
import DpsFormDesktop from './DpsFormDesktop';
import DpsFormMobile from './DpsFormMobile';
import Checks from '../../commons/components/inputs/Checks';

class SubscriptionFormThirdPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: window.innerWidth > 768,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.isDesktop);
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'vpvDPS',
        },
        dataLayerName: 'PageSubProducts',
      };
      TagManager.dataLayer(tagManagerArgs);

      ReactGA.event('funnel_step_viewed', {
        user_id: `CL_RUT_${window.sessionStorage.getItem('hashID')}`,
        funnel_name: 'contratacion-seguro',
        product_name: 'web-vida-legado',
        funnel_step_name: 'paso-dps',
        assist_type: 'autoasistido',
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.isDesktop);
  }

  isDesktop = () => this.setState({ isDesktop: window.innerWidth > 768 });

  submitForm = values => {
    const hasDpsWithValueOne = Object.entries(values).some(([key, value]) => {
      return key.startsWith('DPS') && value === '1';
    });
    ReactGA.event('user_interaction', {
      action: !hasDpsWithValueOne ? 'dps-sin-preexistencia' : 'dps-con-preexistencia',
      location: 'dps',
      flow: 'vida-con-ahorro',
    });

    // eslint-disable-next-line react/destructuring-assignment
    this.props.handleSubmit();
  };

  render() {
    const { handleSubmit, previousPage, pristine, submitting } = this.props;
    const { isDesktop } = this.state;

    return (
      <div className="col-12 col-md-10 wizard-card">
        <form onSubmit={handleSubmit(this.submitForm)} noValidate>
          <div className="w-100 flex-wrap">
            <div className="col-12">
              <h2 className="wizard-card--title">
                Declaración personal de salud (DPS)
                <div className="progress-step">
                  <div className="done" />
                  <div className="done" />
                  <div className="current" />
                  <div />
                </div>
              </h2>
            </div>
            <div className="col-12 mb40">
              <p>
                Le solicitamos responder si usted alguna vez ha sido tratado, le han indicado que
                padece o tiene cualquier indicación conocida de alguna de estas enfermedades,
                dolencias o situaciones de salud:
              </p>
            </div>

            {/* Formulario DPS */}
            {isDesktop ? <DpsFormDesktop /> : <DpsFormMobile />}

            <div className="col-12 mt50 text-justify">
              <p>
                Teniendo en consideración lo declarado por usted, la compañía seguradora acepta los
                riesgos expuestos, excluyendo de todas las coberturas del seguro la(s)
                enfermedad(es) o situación(es) preexistente(s) arriba indicada(s) y sus
                consecuencias.
              </p>
              <p>
                Aceptación: Otorgo mi consentimiento a que si la causa de un siniestro que me
                afecte fuese producto, derive o estuviese relacionada con algunas de las
                situaciones de salud o enfermedad antes indicadas, se consideren ellas como
                situaciones de salud o enfermedad diagnosticadas o conocidas antes de la
                contratación de este seguro, con la correspondiente exclusión de cobertura.
              </p>
            </div>
            <div className="col-12 text-center mt50">
              <Field
                name="declaration"
                component={Checks}
                text="Declaro que he respondido y leído mi declaración personal de salud a consciencia"
              />
            </div>
          </div>
          <div className="row justify-content-center mt30 buttons-container">
            <div className="col-12 col-sm-5 col-md-4">
              <button
                className="btn white back-to-contact-info"
                type="button"
                onClick={previousPage}
              >
                Volver
              </button>
            </div>
            <div className="col-12 col-sm-5 col-md-4">
              <button
                className="btn primary go-to-beneficiaries"
                type="submit"
                disabled={pristine || submitting}
              >
                Continuar
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SubscriptionFormThirdPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default SubscriptionFormThirdPage;
