import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const Navbar = ({ location }) => (
  <nav className="col-12 col-md-9 steps">
    <div className="row no-gutters">
      <div className="col-4">
        <div
          className={`justify-content-center align-items-center d-flex steps--step ${
            location === '/' || location === '/subscription' || location === '/success'
              ? 'active'
              : ''
          }`}
        >
          <span>1</span>
          <p>Ingresa tus datos</p>
        </div>
      </div>
      <div className="col-4">
        <div
          className={`justify-content-center align-items-center d-flex steps--step ${
            location === '/subscription' || location === '/success' ? 'active' : ''
          }`}
        >
          <span>2</span>
          <p>Completa tu información</p>
        </div>
      </div>
      <div className="col-4">
        <div
          className={`justify-content-center align-items-center d-flex steps--step ${
            location === '/success' ? 'active' : ''
          }`}
        >
          <span>3</span>
          <p>Ya estás asegurado</p>
        </div>
      </div>
    </div>
  </nav>
);

Navbar.propTypes = {
  location: PropTypes.string.isRequired,
};

export const mapStateToProps = state => ({
  location: state.router.location.pathname,
});

const connectedNavBar = connect(mapStateToProps)(Navbar);

export default connectedNavBar;
