import { reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import SubscriptionFormFifthPage from '../components/SubscriptionFormFifthPage';
import validate from '../fixtures/validate';
import {
  getSubscriptionLoading,
  getFormSuscriptionValues,
  getPaymentMethod,
} from '../actions/selectors';
import { actionSetPaymentType } from '../actions/creators';
import { getSelectedSubProduct, getQuoteForm } from '../../quote/actions/selectors';
import focusInputOnError from '../../commons/actions/focusInputOnError';

export const mapStateToProps = state => {
  const { name, paternal, creditNumber, cardYear, cardMonth } = getFormSuscriptionValues(state);
  const fullName = `${name} ${paternal}`;
  const month = cardMonth || '00';
  const year = cardYear || '00';
  const creditExpiry = `${month}${year}`;

  return {
    loading: getSubscriptionLoading(state),
    selectedSubProduct: getSelectedSubProduct(state),
    rut: getQuoteForm(state).rut,
    payMethod: getPaymentMethod(state),
    fullName,
    creditNumber,
    creditExpiry,
  };
};

export const mapDispatchToProps = dispatch => ({
  setPaymentType: shortName => dispatch(actionSetPaymentType(shortName)),
  changePayMethod: value => dispatch(change('subscription', 'payMethod', value)),
});

const form = reduxForm({
  form: 'subscription',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: focusInputOnError,
})(SubscriptionFormFifthPage);

const connectedSubscriptionForm = connect(mapStateToProps, mapDispatchToProps)(form);

export default connectedSubscriptionForm;
