import { reduxForm } from 'redux-form';
import SubscriptionFormThirdPage from '../components/SubscriptionFormThirdPage';
import validate from '../fixtures/validate';
import focusInputOnError from '../../commons/actions/focusInputOnError';

const connectedSubscriptionForm = reduxForm({
  form: 'subscription',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: focusInputOnError,
})(SubscriptionFormThirdPage);

export default connectedSubscriptionForm;
