import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import { PropTypes } from 'prop-types';
import { Field } from 'redux-form';
import Input from '../../commons/input/Input';
import Select from '../../commons/select/Select';
import { genders } from '../fixtures/data';

const SubscriptionFormFirstPage = ({
  handleSubmit,
  backToSubProducts,
  initialValues: { rut, birthdate },
}) => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'vpvDatosPersonales',
        },
        dataLayerName: 'PageSubProducts',
      };

      TagManager.dataLayer(tagManagerArgs);

      ReactGA.event('funnel_step_viewed', {
        user_id: `CL_RUT_${window.sessionStorage.getItem('hashID')}`,
        funnel_name: 'contratacion-seguro',
        product_name: 'web-vida-legado',
        funnel_step_name: 'paso-informacion-personal',
        assist_type: 'autoasistido',
      });
    }
  }, []);

  return (
    <div className="col-12 col-md-10 wizard-card">
      <form onSubmit={handleSubmit} noValidate>
        <div className="w-100 flex-wrap">
          <div className="col-12">
            <h2 className="wizard-card--title">
              Datos personales
              <div className="progress-step">
                <div className="current" />
                <div />
                <div />
                <div />
              </div>
            </h2>
          </div>
          <div className="col-12 col-sm-6 form-group">
            <div>
              <span className="text-bold">Rut</span>
              <p>{rut}</p>
            </div>
          </div>
          <div className="w-100 d-none d-sm-block" />
          <div className="col-12 col-sm-6 form-group">
            <div>
              <span className="text-bold">Fecha de nacimiento</span>
              <p>{birthdate}</p>
            </div>
          </div>
          <div className="w-100 d-none d-sm-block" />
          <div className="col-12 col-sm-6 form-group">
            <label htmlFor="name">Nombre</label>
            <Field name="name" placeholder="Nombre" component={Input} />
          </div>
          <div className="w-100 d-none d-sm-block" />
          <div className="col-12 col-sm-6 form-group">
            <label htmlFor="paternal">Apellido paterno</label>
            <Field name="paternal" placeholder="Apellido paterno" component={Input} />
          </div>
          <div className="w-100 d-none d-sm-block" />
          <div className="col-12 col-sm-6 form-group">
            <label htmlFor="maternal">Apellido materno</label>
            <Field name="maternal" placeholder="Apellido materno" component={Input} />
          </div>
          <div className="w-100 d-none d-sm-block" />
          <div className="col-12 col-sm-6 form-group">
            <label htmlFor="gender">Género</label>
            <Field name="gender" placeholder="Selecciona.." component={Select} options={genders} />
          </div>
        </div>
        <div className="row justify-content-center mt30 buttons-container">
          <div className="col-12 col-sm-5 col-md-4">
            <button className="btn white back-to-matrix" type="button" onClick={backToSubProducts}>
              Volver
            </button>
          </div>
          <div className="col-12 col-sm-5 col-md-4">
            <button className="btn primary go-to-contact-info" type="submit">
              Continuar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

SubscriptionFormFirstPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  backToSubProducts: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    rut: PropTypes.string,
    email: PropTypes.string,
    birthdate: PropTypes.string,
    plan: PropTypes.string,
  }),
};

SubscriptionFormFirstPage.defaultProps = {
  initialValues: {
    rut: '',
    email: '',
    birthdate: '',
    plan: '',
  },
};

export default SubscriptionFormFirstPage;
