import React from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import PropTypes from 'prop-types';

const customCheckStyles = (type, value) =>
  type === 'checkbox' && value
    ? {
        backgroundColor: '#30b033',
        border: 'none',
      }
    : {};

const customIStyles = (type, value) => (type === 'checkbox' && value ? { color: '#fff' } : {});

const Checks = ({ id, type, input, disabled, text, meta: { touched, error }, className }) => (
  <div className="d-inline-block">
    <label
      className={`custom-check${type === 'radio' ? ' custom-check__radio' : ''}${` ${className}`}`}
      htmlFor={id || input.name}
    >
      <div className="custom-check--container">
        <input {...input} id={id || input.name} type={type} disabled={disabled} />
        <div className="custom-check--bg" style={customCheckStyles(type, input.value)} />
        <i
          className={type !== 'radio' ? 'material-icons' : ''}
          style={customIStyles(type, input.value)}
        >
          {type !== 'radio' && 'check'}
        </i>
      </div>
      <span>{text}</span>
    </label>
    {touched && error && <small className="invalid">{error}</small>}
  </div>
);

Checks.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
};

Checks.defaultProps = {
  id: '',
  type: 'checkbox',
  disabled: false,
  text: '',
  className: '',
};

export default Checks;
