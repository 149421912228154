import React from 'react';
import './assets/quote.css';

export const QuoteFormFloatingBox = () => (
  <aside className="bubble-home">
    <p>
      Ahorra
      <b>50%</b>
      <small>
        De lo pagado
        <br />
        mensualmente
      </small>
    </p>
  </aside>
);

export default QuoteFormFloatingBox;
