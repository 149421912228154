import { connect } from 'react-redux';
import { getQuoteLoading } from '../actions/selectors';
import { actionSubmitQuoteForm } from '../actions/creators';
import Quote from '../components/Quote';

export const mapStateToProps = state => ({
  loading: getQuoteLoading(state),
});

export const mapDispatchToProps = dispatch => ({
  submitQuoteForm: payload => dispatch(actionSubmitQuoteForm(payload)),
});

const connectedQuote = connect(mapStateToProps, mapDispatchToProps)(Quote);

export default connectedQuote;
