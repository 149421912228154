import React from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import PropTypes from 'prop-types';

const Input = ({ type, input, placeholder, disabled, meta: { touched, error } }) => (
  <div>
    <input
      {...input}
      id={input.name}
      type={type}
      className="form-control"
      placeholder={placeholder}
      disabled={disabled}
    />
    {touched && error && <small className="invalid">{error}</small>}
  </div>
);

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  disabled: false,
};

export default Input;
