import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Collapse } from 'reactstrap';
import { Field } from 'redux-form';
import Select from '../../commons/select/Select';
import Input from '../../commons/input/Input';
import InputNumberFormatter from '../../commons/components/inputs/InputNumberFormatter';
import { formatRut, parseStringToNumber } from '../../commons/helpers';

class Beneficiary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      name: '',
      paternal: '',
      maternal: '',
      percentage: '',
    };
  }

  toggle = () => this.setState(prevState => ({ collapse: !prevState.collapse }));

  handleOnChange =
    type =>
    ({ target: { value } }) =>
      this.setState({ [type]: value });

  render() {
    const { relationshipOptions, index, total } = this.props;
    const { collapse, name, paternal, maternal, percentage } = this.state;

    if (index === 1 && total === 1) this.setState({ percentage: '' });

    return (
      <div className="row mt30">
        <div className="col-12">
          <h3
            className={`border-bottom pb10 ${total > 1 ? 'cursor-pointer' : ''}`}
            onClick={total > 1 ? this.toggle : undefined}
            role="presentation"
          >
            {name.length > 0 ? `${name} ${paternal} ${maternal}` : 'Beneficiario'}
            {total <= 1 && ' - asignado 100%'}
            {percentage.length > 0 ? ` - asignado ${percentage}%` : ''}
            {total > 1 && (
              <i className="material-icons float-right">
                {collapse ? 'expand_less' : 'expand_more'}
              </i>
            )}
          </h3>
        </div>
        <Collapse className="row ml0 mr0" isOpen={collapse}>
          <div className="col-12 col-sm-4 form-group">
            <label htmlFor={`beneficiary-rut-${index}`}>Rut</label>
            <Field
              name={`beneficiary-rut-${index}`}
              component={Input}
              placeholder="Ej 12345678-9"
              normalize={formatRut}
            />
          </div>
          <div className="w-100 d-none d-sm-block" />
          <div className="col-12 col-sm-4 form-group">
            <label htmlFor={`beneficiary-name-${index}`}>Nombre</label>
            <Field
              name={`beneficiary-name-${index}`}
              component={Input}
              placeholder="Nombre"
              value={name}
              onChange={this.handleOnChange('name')}
            />
          </div>
          <div className="col-12 col-sm-4 form-group">
            <label htmlFor={`beneficiary-paternal-${index}`}>Apellido Paterno</label>
            <Field
              name={`beneficiary-paternal-${index}`}
              component={Input}
              placeholder="Apellido Paterno"
              value={paternal}
              onChange={this.handleOnChange('paternal')}
            />
          </div>
          <div className="col-12 col-sm-4 form-group">
            <label htmlFor={`beneficiary-maternal-${index}`}>Apellido Materno</label>
            <Field
              name={`beneficiary-maternal-${index}`}
              component={Input}
              placeholder="Apellido Materno"
              value={maternal}
              onChange={this.handleOnChange('maternal')}
            />
          </div>
          <div className="w-100 d-none d-sm-block" />
          <div className="col-12 col-sm-4 form-group">
            <label htmlFor={`beneficiary-birthdate-${index}`}>Fecha de nacimiento</label>
            <Field
              name={`beneficiary-birthdate-${index}`}
              placeholder="Ej. 31-12-2000"
              inputFormat="##-##-####"
              mask={['d', 'd', 'm', 'm', 'y', 'y', 'y', 'y']}
              component={InputNumberFormatter}
            />
          </div>
          <div className="col-12 col-sm-4 form-group">
            <label htmlFor={`beneficiary-relationship-${index}`}>Parentesco/Relación</label>
            <Field
              name={`beneficiary-relationship-${index}`}
              component={Select}
              options={relationshipOptions}
              placeholder="Selecciona..."
            />
          </div>
          <div className={`col-12 col-sm-4 form-group ${total <= 1 ? 'd-none' : ''}`}>
            <label htmlFor={`beneficiary-percentage-${index}`}>% asignado</label>
            <Field
              name={`beneficiary-percentage-${index}`}
              component={Input}
              placeholder="Ej. 10"
              max="100"
              min="0"
              type="number"
              normalize={parseStringToNumber}
              value={percentage}
              onChange={this.handleOnChange('percentage')}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

Beneficiary.propTypes = {
  relationshipOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  index: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

Beneficiary.defaultProps = {
  relationshipOptions: [
    {
      value: 'PARENTS',
      name: 'Padres',
    },
    {
      value: 'CHILDREN',
      name: 'Hijos',
    },
    {
      value: 'SPOUSE',
      name: 'Cónyuge',
    },
    {
      value: 'OTHERS',
      name: 'Otros',
    },
  ],
};

export default Beneficiary;
