import { connect } from 'react-redux';
import SubscriptionForm from '../components/SubscriptionForm';
import { actionSubmitSubscriptionForm } from '../actions/creators';

export const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch(actionSubmitSubscriptionForm(payload)),
});

const connectedSubscriptionForm = connect(null, mapDispatchToProps)(SubscriptionForm);

export default connectedSubscriptionForm;
