import React, { Component } from 'react';
import { Link } from 'react-scroll';

class ScrollTopButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenButton: false,
    };
  }

  componentDidMount() {
    this.scrolled();
    window.addEventListener('scroll', this.scrolled);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrolled);
  }

  scrolled = () => {
    const actualScroll = window.scrollY > 200;
    this.setState({
      hiddenButton: actualScroll,
    });
  };

  render() {
    const { hiddenButton } = this.state;

    return (
      <Link
        className={`scroll-up-button ${hiddenButton ? 'show' : 'hide'}`}
        activeClass=" "
        to="inicio"
        spy
        smooth
        offset={-300}
        duration={500}
      >
        <i className="material-icons">keyboard_arrow_up</i>
      </Link>
    );
  }
}

export default ScrollTopButton;
