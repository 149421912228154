import React from 'react';
import PropTypes from 'prop-types';
import Beneficiary from './Beneficiary';

const Beneficiaries = ({ index }) => {
  const array = [...Array(index)];
  for (let i = 0; i < index; i += 1) {
    array[i] = i + 1;
  }
  return (
    <div className="col-12">
      {array.map((x, i) => (
        <Beneficiary key={x} index={(i + 1).toString()} total={array.length} />
      ))}
    </div>
  );
};

Beneficiaries.propTypes = {
  index: PropTypes.number,
};

Beneficiaries.defaultProps = {
  index: 1,
};

export default Beneficiaries;
