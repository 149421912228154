export default errors => {
  const elements = Object.keys(errors)
    .map(fieldName => `[name="${fieldName}"]`)
    .join(',');
  const element = document.querySelector(elements);

  if (element && element.focus) {
    element.focus();
  }
};
