import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Field } from 'redux-form';
import { Collapse } from 'reactstrap';
import { PropTypes } from 'prop-types';
import Cards from 'react-credit-cards';
import Select from '../../commons/select/Select';
import Checks from '../../commons/components/inputs/Checks';
import {
  paymentMethod,
  fixedMonths,
  fixedYears,
  bankDetails,
  billingDayDetails,
} from '../fixtures/data';
import { formatPrice } from '../../commons/helpers';
import InputNumberFormatter from '../../commons/components/inputs/InputNumberFormatter';
import 'react-credit-cards/es/styles-compiled.css';

export class PaymentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCMR: false,
      showOTHERS: true,
      showMANDATE: false,
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'vpvPago',
        },
        dataLayerName: 'PageSubProducts',
      };

      TagManager.dataLayer(tagManagerArgs);

      ReactGA.event('funnel_step_viewed', {
        user_id: `CL_RUT_${window.sessionStorage.getItem('hashID')}`,
        funnel_name: 'contratacion-seguro',
        product_name: 'web-vida-legado',
        funnel_step_name: 'paso-pago',
        assist_type: 'autoasistido',
      });
    }
  }

  toggle = shortName => {
    const { setPaymentType, changePayMethod } = this.props;
    switch (shortName) {
      case 'CMR':
        this.setState(() => ({ showCMR: true, showOTHERS: false, showMANDATE: false }));
        break;
      case 'OTHERS':
        this.setState(() => ({ showCMR: false, showOTHERS: true, showMANDATE: false }));
        break;
      case 'MANDATE':
        this.setState(() => ({ showCMR: false, showOTHERS: false, showMANDATE: true }));
        break;
      default:
        break;
    }
    setPaymentType(shortName);
    changePayMethod(shortName);
  };

  toggleDropDown = () => this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));

  render() {
    const { showCMR, showOTHERS, showMANDATE } = this.state;
    const {
      selectedSubProduct,
      handleSubmit,
      previousPage,
      loading,
      rut,
      creditNumber,
      fullName,
      years,
      months,
      banks,
      billingDays,
      creditExpiry,
    } = this.props;

    const textoTerms = (
      <div>
        <p className="px-14">
          Al seleccionar esta casilla aceptas los&nbsp;
          <a
            href="https://sfestaticos.blob.core.windows.net/pmc/vida/assets/pdf/propuesta-generica-vida-ahorro.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            términos y condiciones&nbsp;
          </a>
          del seguro y declaras conocer nuestra&nbsp;
          <a
            href="https://sfestaticos.blob.core.windows.net/chile/pdf/politica-de-privacidad-protocolizada-seguros.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            política de privacidad.&nbsp;
          </a>
          También declaras ser asegurado y pagador de este seguro.
        </p>
      </div>
    );

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mb50">
            <h1 className="text-center mt30">Elige tu medio de pago</h1>
            <h3 className="text-center mt30">Paga con cualquier tarjeta de crédito</h3>
            <div className="row mt50 justify-content-center">
              {paymentMethod.map(element => (
                <div className="col-12 col-sm-4 col-lg-4 d-none d-md-block" key={element.name}>
                  <article
                    onClick={() => this.toggle(element.shortName)}
                    onKeyDown={event => event.keyCode === 13 && this.toggle(element.shortName)}
                    role="presentation"
                  >
                    <span className="tick" />
                    <figure>
                      <img
                        className="d-flex mx-auto img-fluid"
                        src={element.url}
                        alt={`Método de Pago ${element.name}`}
                      />
                    </figure>
                  </article>
                  <div className="w-100 d-sm-none mb20" />
                </div>
              ))}

              {/* Mobile */}
              <div className="col-12 d-block d-md-none">
                {paymentMethod.map(element => (
                  <img
                    className="d-flex mx-auto img-fluid"
                    src={element.url}
                    alt={`Método de Pago ${element.name}`}
                  />
                ))}
              </div>
              {/* Fin Mobile */}

              {/* Acordeón CMR */}
              <Collapse className="col-12 mt30" isOpen={showCMR}>
                <form onSubmit={handleSubmit}>
                  <Field name="payMethod" component="input" type="hidden" />
                  <div className="col-12 wizard-card">
                    <div className="row">
                      <div className="col-12 col-sm-6 order-1 order-sm-3">
                        <div className="row">
                          <div className="col-12 form-group">
                            <div>
                              <span className="text-bold">Rut</span>
                              <p>{rut}</p>
                            </div>
                          </div>
                          <div className="w-100 d-none d-sm-block" />
                          <div className="col-12 col-md-10 col-lg-8 form-group mb0">
                            <label htmlFor="tarjeta">Número de tarjeta CMR</label>
                            <Field
                              name="cardNumber"
                              placeholder="1234 5678 9012 3456"
                              inputFormat="####-####-####-####"
                              component={InputNumberFormatter}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 border-left pt20 pb20 order-4 order-sm-4">
                        <div className="row">
                          <article className="col-12 col-lg-8 mx-auto">
                            <div className="product-card no-shadow mb0">
                              <section className="recommended-section">
                                <h3>{selectedSubProduct?.properties?.name}</h3>
                                <hr />
                              </section>
                              <section>
                                <figure>
                                  <img
                                    src="//sfestaticos.blob.core.windows.net/guideline/companies/metlife.svg"
                                    alt="Metlife"
                                  />
                                </figure>
                                <hr />
                                <h3 className="product-card--price">
                                  <span>
                                    <img
                                      src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/money.svg"
                                      alt="Simbolo Moneda"
                                      height="20px"
                                    />
                                  </span>
                                  {formatPrice(selectedSubProduct.premiums.local)}
                                </h3>
                                <p className="mb0">{`UF ${selectedSubProduct.premiums.monthly} mensuales`}</p>
                              </section>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center buttons-container text-center">
                    <div className="row mt20">
                      <div className="col-12 col-sm-auto mx-auto mt30">
                        <p className="px-14">
                          Recuerda que tienes derecho a retracto en la compra de nuestro seguro.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-auto mx-auto mt10">
                        <Field name="declarationCard" component={Checks} text={textoTerms} />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt50 buttons-container">
                    <div className="col-12 col-sm-5 col-md-4">
                      <button
                        className="btn white back-to-beneficiaries"
                        type="button"
                        onClick={previousPage}
                        disabled={loading}
                      >
                        Volver
                      </button>
                    </div>
                    <div className="col-12 col-sm-5 col-md-4">
                      <button
                        className={`btn primary button-pay ${loading ? 'loading' : ''}`}
                        type="submit"
                        disabled={loading}
                      >
                        <span>Contratar</span>
                        {loading && <i className="element-spinner" />}
                      </button>
                    </div>
                  </div>
                </form>
              </Collapse>
              {/* fin Acordeón CMR */}
              {/* Acordeón Tarjetas de Crédito */}
              <Collapse className="col-12 mt30" isOpen={showOTHERS}>
                <form onSubmit={handleSubmit}>
                  <Field name="payMethod" component="input" type="hidden" />
                  <div className="col-12 wizard-card">
                    <div className="row">
                      <div className="col-12 col-sm-6 order-1 order-sm-3">
                        <div className="row mb40">
                          <div className="col-12">
                            <Cards
                              number={creditNumber}
                              name={fullName}
                              expiry={creditExpiry}
                              cvc=""
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 form-group mb20">
                            <div>
                              <span className="text-bold">Rut</span>
                              <p>{rut}</p>
                            </div>
                          </div>
                          <div className="w-100 d-none d-sm-block" />
                          <div className="col-12 col-md-10 col-lg-8 form-group mb0">
                            <label htmlFor="tarjeta">Número de tarjeta</label>
                            <Field
                              name="creditNumber"
                              placeholder="1234 5678 9012 3456"
                              inputFormat="#### #### #### ####"
                              component={InputNumberFormatter}
                            />
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 col-sm-4 form-group">
                                <label htmlFor="cardMonth">Mes</label>
                                <Field
                                  name="cardMonth"
                                  component={Select}
                                  options={months}
                                  placeholder="Selecciona..."
                                />
                              </div>
                              <div className="col-12 col-sm-4 form-group">
                                <label htmlFor="cardYear">Año</label>
                                <Field
                                  name="cardYear"
                                  component={Select}
                                  options={years}
                                  placeholder="Selecciona..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 border-left pt20 pb20 order-4 order-sm-4">
                        <div className="row">
                          <article className="col-12 col-lg-8 mx-auto">
                            <div className="product-card no-shadow mb0">
                              <section className="recommended-section">
                                <h3>{selectedSubProduct.properties.name}</h3>
                                <hr />
                              </section>
                              <section>
                                <figure>
                                  <img
                                    src="//sfestaticos.blob.core.windows.net/guideline/companies/metlife.svg"
                                    alt="Metlife"
                                  />
                                </figure>
                                <hr />
                                <h3 className="product-card--price">
                                  <span>
                                    <img
                                      src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/money.svg"
                                      alt="Simbolo Moneda"
                                      height="20px"
                                    />
                                  </span>
                                  {formatPrice(selectedSubProduct.premiums.local)}
                                </h3>
                                <p className="mb0">{`UF ${selectedSubProduct.premiums.monthly} mensuales`}</p>
                              </section>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center buttons-container text-center">
                    <div className="row mt20">
                      <div className="col-12 col-sm-auto mx-auto mt10">
                        <p className="px-14">
                          Recuerda que tienes derecho a retracto en la compra de nuestro seguro.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-auto mx-auto mt10">
                        <Field name="declarationCard" component={Checks} text={textoTerms} />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt50 buttons-container">
                    <div className="col-12 col-sm-5 col-md-4">
                      <button
                        className="btn white back-to-beneficiaries"
                        type="button"
                        onClick={previousPage}
                        disabled={loading}
                      >
                        Volver
                      </button>
                    </div>
                    <div className="col-12 col-sm-5 col-md-4">
                      <button
                        className={`btn primary button-pay ${loading ? 'loading' : ''}`}
                        type="submit"
                        disabled={loading}
                      >
                        <span>Contratar</span>
                        {loading && <i className="element-spinner" />}
                      </button>
                    </div>
                  </div>
                </form>
              </Collapse>
              {/* fin Acordeón Tarjetas de Crédito */}
              {/* Acordeón Mandato Bancario */}
              <Collapse className="col-12 mt30" isOpen={showMANDATE}>
                <form onSubmit={handleSubmit}>
                  <Field name="payMethod" component="input" type="hidden" />
                  <div className="col-12 wizard-card">
                    <div className="row">
                      <div className="col-12 order-2 order-sm-0 payment-pac">
                        <h2 className="px-28 d-none d-sm-block text-center mb10">
                          Pasos importantes para suscribir tu PAC
                        </h2>
                        <h2 className="px-20 d-block d-sm-none mt30">
                          Pasos importantes para
                          <br />
                          suscribir tu PAC
                        </h2>
                        <h3 className="px-28 d-none d-sm-block text-green text-center mb40">
                          Considera estos pasos y así obtendrás tu póliza ;)
                        </h3>
                        <h3 className="px-20 d-block d-sm-none text-green mb40">
                          Considera estos pasos y así obtendrás tu póliza ;)
                        </h3>
                      </div>
                      <div className="col-12 d-flex flex-wrap mb30 order-3 order-sm-1 payment-pac">
                        <div className="col-12 col-sm-3">
                          <figure className="payment-instructions">
                            <span className="step">1</span>
                            <div className="image-container d-none d-md-block">
                              <img
                                src="//sfestaticos.blob.core.windows.net/guideline/icons/banco.svg"
                                alt="Banco"
                              />
                            </div>
                            <figcaption>Suscribe el PAC al tu banco</figcaption>
                          </figure>
                        </div>
                        <div className="col-12 col-sm-3">
                          <figure className="payment-instructions">
                            <span className="step">2</span>
                            <div className="image-container d-none d-md-block">
                              <img
                                src="//sfestaticos.blob.core.windows.net/guideline/icons/mail-payment.svg"
                                alt="Correo"
                              />
                            </div>
                            <figcaption>Recibirás un correo con instrucciones</figcaption>
                          </figure>
                        </div>
                        <div className="col-12 col-sm-3">
                          <figure className="payment-instructions">
                            <span className="step">3</span>
                            <div className="image-container d-none d-md-block">
                              <img
                                src="//sfestaticos.blob.core.windows.net/guideline/icons/no-pago.svg"
                                alt="No pago"
                              />
                            </div>
                            <figcaption>
                              Para no tener problemas de pago comprueba tu suscripción
                            </figcaption>
                          </figure>
                        </div>
                        <div className="col-12 col-sm-3">
                          <figure className="payment-instructions">
                            <span className="step">4</span>
                            <div className="image-container d-none d-md-block">
                              <img
                                src="//sfestaticos.blob.core.windows.net/guideline/icons/tiempo.svg"
                                alt="Tiempo"
                              />
                            </div>
                            <figcaption>Tienes hasta 5 días para suscribir tu PAC</figcaption>
                          </figure>
                        </div>
                        <div className="w-100 d-block d-sm-none mb30" />
                      </div>
                      <div className="col-12 mb30 d-none d-sm-block order-4">
                        <h2 className="px-20">
                          La cuenta corriente que ingreses debe estar a
                          <span className="text-primary">&nbsp;nombre del RUT asegurado.</span>
                        </h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6 order-1 order-sm-3">
                        <div className="row">
                          <div className="col-12 form-group">
                            <div>
                              <span className="text-bold">Rut</span>
                              <p>{rut}</p>
                            </div>
                          </div>
                          <div className="w-100 d-none d-sm-block" />
                          <div className="col-12 col-sm-10 form-group mb20">
                            <label htmlFor="banco">Seleccionar Banco</label>
                            <Field
                              name="nameBank"
                              component={Select}
                              options={banks}
                              placeholder="Selecciona..."
                            />
                          </div>
                          <div className="col-12 col-sm-10 form-group">
                            <label htmlFor="cuenta">Nº de Cuenta Corriente</label>
                            <Field
                              name="accountBank"
                              placeholder=""
                              inputFormat="################"
                              component={InputNumberFormatter}
                            />
                          </div>
                          <div className="col-12 col-sm-10 form-group">
                            <label htmlFor="diacobro">Día de cobro</label>
                            <Field
                              name="billingDay"
                              component={Select}
                              options={billingDays}
                              placeholder="Selecciona..."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 border-left pt20 pb20 order-4 order-sm-4">
                        <div className="row">
                          <article className="col-12 col-lg-8 mx-auto">
                            <div className="product-card no-shadow mb0">
                              <section className="recommended-section">
                                <h3>{selectedSubProduct.properties.name}</h3>
                                <hr />
                              </section>
                              <section>
                                <figure>
                                  <img
                                    src="//sfestaticos.blob.core.windows.net/guideline/companies/metlife.svg"
                                    alt="Metlife"
                                  />
                                </figure>
                                <hr />
                                <h3 className="product-card--price">
                                  <span>
                                    <img
                                      src="//sfestaticos.blob.core.windows.net/pmc/vida/assets/svg/money.svg"
                                      alt="Simbolo Moneda"
                                      height="20px"
                                    />
                                  </span>
                                  {formatPrice(selectedSubProduct.premiums.local)}
                                </h3>
                                <p className="mb0">{`UF ${selectedSubProduct.premiums.monthly} mensuales`}</p>
                              </section>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mt50 buttons-container">
                    <div className="col-12 col-sm-5 col-md-4">
                      <button
                        className="btn white back-to-beneficiaries"
                        type="button"
                        onClick={previousPage}
                        disabled={loading}
                      >
                        Volver
                      </button>
                    </div>
                    <div className="col-12 col-sm-5 col-md-4">
                      <button
                        className={`btn primary button-pay ${loading ? 'loading' : ''}`}
                        type="submit"
                        disabled={loading}
                      >
                        <span>Contratar</span>
                        {loading && <i className="element-spinner" />}
                      </button>
                    </div>
                  </div>
                </form>
              </Collapse>
              {/* fin Acordeón Mandato Bancario */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PaymentView.propTypes = {
  selectedSubProduct: PropTypes.shape({
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    premiums: PropTypes.shape({
      annual: PropTypes.number.isRequired,
      local: PropTypes.number.isRequired,
      monthly: PropTypes.number.isRequired,
      period: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }).isRequired,
    properties: PropTypes.shape({
      benefits: PropTypes.string.isRequired,
      campaign: PropTypes.string,
      characteristics: PropTypes.string,
      deductible: PropTypes.number.isRequired,
      details: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  setPaymentType: PropTypes.func.isRequired,
  changePayMethod: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  rut: PropTypes.string.isRequired,
  creditNumber: PropTypes.string,
  creditExpiry: PropTypes.string,
  fullName: PropTypes.string,
  months: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  years: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  banks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  billingDays: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

PaymentView.defaultProps = {
  loading: false,
  creditNumber: '',
  creditExpiry: '',
  fullName: '',
  months: fixedMonths,
  years: fixedYears(),
  banks: bankDetails,
  billingDays: billingDayDetails,
};

export default PaymentView;
