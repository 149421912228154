import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubscriptionFormFirstPage from '../containers/SubscriptionFormFirstPage';
import SubscriptionFormSecondPage from '../containers/SubscriptionFormSecondPage';
import SubscriptionFormThirdPage from '../containers/SubscriptionFormThirdPage';
import SubscriptionFormFourthPage from '../containers/SubscriptionFormFourthPage';
import SubscriptionFormFifthPage from '../containers/SubscriptionFormFifthPage';
import '../assets/subscription.css';

class SubscriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = { page: 1 };
  }

  componentDidUpdate(prevProps, prevState) {
    const { page } = this.state;

    if (page !== prevState.page) {
      window.scrollTo(0, 0);
    }
  }

  nextPage = () => this.setState(state => ({ page: state.page + 1 }));

  previousPage = () => this.setState(state => ({ page: state.page - 1 }));

  render() {
    const { page } = this.state;
    const { onSubmit } = this.props;

    return (
      <div className="container mb50 subscription">
        <div className="row">
          <div className="col-12">
            <h1 className={`text-center mt60 mb50 ${page === 5 ? 'd-none' : ''}`}>
              Completa la siguiente información
            </h1>
            <div className="row mt50">
              {page === 1 && <SubscriptionFormFirstPage onSubmit={this.nextPage} />}
              {page === 2 && (
                <SubscriptionFormSecondPage
                  onSubmit={this.nextPage}
                  previousPage={this.previousPage}
                />
              )}
              {page === 3 && (
                <SubscriptionFormThirdPage
                  onSubmit={this.nextPage}
                  previousPage={this.previousPage}
                />
              )}
              {page === 4 && (
                <SubscriptionFormFourthPage
                  onSubmit={this.nextPage}
                  previousPage={this.previousPage}
                />
              )}
              {page === 5 && (
                <SubscriptionFormFifthPage onSubmit={onSubmit} previousPage={this.previousPage} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SubscriptionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SubscriptionForm;
