import * as quoteConstants from './quote/actions/constants';
import * as subscriptionConstants from './subscription/actions/constants';
import * as commonsConstants from './commons/actions/constants';

const URL_QUOTE_PLANS = '/v1/cl/plan';
const URL_QUOTE = '/quote';
const URL_SUBSCRIPTION = '/closure';
const TOKEN_TYPE = 'Bearer';
const CLOSING_TOKEN_HEADER_NAME = 'x-ct-id';

export {
  quoteConstants,
  subscriptionConstants,
  commonsConstants,
  URL_QUOTE_PLANS,
  URL_QUOTE,
  TOKEN_TYPE,
  URL_SUBSCRIPTION,
  CLOSING_TOKEN_HEADER_NAME,
};
