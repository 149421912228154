export const SUBMIT_SUBSCRIPTION_FORM = 'SUBMIT_SUBSCRIPTION_FORM';
export const SET_SUBSCRIPTION_FORM = 'SET_SUBSCRIPTION_FORM';
export const BACK_TO_SUBPRODUCTS = 'BACK_TO_SUBPRODUCTS';
export const CHANGE_BENEFICIARIES_SELECT = 'CHANGE_BENEFICIARIES_SELECT';
export const CHANGE_SUBSCRIPTION_LOADING = 'CHANGE_SUBSCRIPTION_LOADING';
export const SET_PROPOSAL = 'SET_PROPOSAL';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const DOCUMENT_ID_FIELD = 'rut';
export const BENEFICIARIES_FIELD = 'beneficiaries';
export const CARD_NUMBER = 'cardNumber';
export const HAVE_VALUE = 'TI';
export const HAS_VALUE = 'TU';
