import React from 'react';
import { PropTypes } from 'prop-types';
import Header from './header/Header';

const Container = ({ children }) => (
  <div>
    <Header />
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
