import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createRootReducer, resetEnhancer } from './reducers';
import sagas from './sagas';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  resetEnhancer(createRootReducer(history)),
  composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
);

sagaMiddleware.run(sagas);

export default store;
