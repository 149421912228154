import { connect } from 'react-redux';
import { actionSelectSubProduct } from '../actions/creators';
import SubProduct from '../components/SubProduct';
import { getSubProduct } from '../actions/selectors';

export const mapDispatchToProps = dispatch => ({
  selectProduct: payload => dispatch(actionSelectSubProduct(payload)),
});

export const mapStateToProps = (state, props) => ({ subProduct: getSubProduct(state, props) });

export default connect(mapStateToProps, mapDispatchToProps)(SubProduct);
