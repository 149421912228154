import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { PropTypes } from 'prop-types';
import TagManager from 'react-gtm-module';
import { Field } from 'redux-form';
import { regions, provinces, communes } from '../../commons/demographics';
import Input from '../../commons/input/Input';
import Select from '../../commons/select/Select';
import InputNumberFormatter from '../../commons/components/inputs/InputNumberFormatter';
import { parseStringToNumber } from '../../commons/helpers';

const regionsOptions = regions.map(region => ({ name: region.name, value: region.code }));

class SubscriptionFormSecondPage extends Component {
  constructor(props) {
    super(props);
    this.state = { provincesOptions: [], communesOptions: [] };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'vpvContacto',
        },
        dataLayerName: 'PageSubProducts',
      };

      TagManager.dataLayer(tagManagerArgs);

      ReactGA.event('funnel_step_viewed', {
        user_id: `CL_RUT_${window.sessionStorage.getItem('hashID')}`,
        funnel_name: 'contratacion-seguro',
        product_name: 'web-vida-legado',
        funnel_step_name: 'paso-informacion-contacto',
        assist_type: 'autoasistido',
      });
    }
  }

  handleRegionChange = event => {
    const regionCode = event.target.value.toString();
    const provincesOptions = provinces
      .filter(province => province.region_code === regionCode)
      .map(province => ({ name: province.name, value: province.code }));

    this.setState({ provincesOptions, communesOptions: [] });
  };

  handleProvinceChange = event => {
    const provinceCode = event.target.value.toString();
    const communesOptions = communes
      .filter(commune => commune.province_code === provinceCode)
      .map(commune => ({ name: commune.name, value: commune.code }));

    this.setState({ communesOptions });
  };

  render() {
    const {
      handleSubmit,
      previousPage,
      quote: { email },
    } = this.props;

    const { provincesOptions, communesOptions } = this.state;

    return (
      <div className="col-12 col-md-10 wizard-card">
        <form onSubmit={handleSubmit} noValidate>
          <div className="w-100 flex-wrap">
            <div className="col-12">
              <h2 className="wizard-card--title">
                Información de contacto
                <div className="progress-step">
                  <div className="done" />
                  <div className="current" />
                  <div />
                  <div />
                </div>
              </h2>
            </div>
            <div className="col-12 col-sm-6 form-group">
              <div>
                <span className="text-bold">Correo</span>
                <p>{email}</p>
              </div>
            </div>
            <div className="w-100 d-none d-sm-block" />
            <div className="col-12 col-sm-6 col-md-5 col-lg-4 form-group">
              <label htmlFor="phone">Teléfono</label>
              <Field
                name="phone"
                placeholder="941234567"
                inputFormat="#########"
                component={InputNumberFormatter}
                isPhone
              />
            </div>
            <div className="w-100 d-none d-sm-block" />
            <div className="col-12 col-sm-8 form-group">
              <label htmlFor="address">Dirección</label>
              <Field
                name="address"
                component={Input}
                placeholder="Ej. Avenida Libertador Bernardo O’Higgins"
              />
            </div>
            <div className="w-100 d-none d-sm-block" />
            <div className="col-12 col-sm-6 col-md-4 form-group">
              <label htmlFor="addressNumber">Número</label>
              <Field
                name="addressNumber"
                placeholder="Ej. 1234"
                normalize={parseStringToNumber}
                component={InputNumberFormatter}
              />
            </div>
            <div className="w-100 d-none d-sm-block" />
            <div className="col-12 col-sm-8 col-md-6 form-group">
              <label htmlFor="region">Región</label>
              <Field
                name="region"
                options={regionsOptions}
                placeholder="Selecciona..."
                component={Select}
                onChange={this.handleRegionChange}
                isDisabled={regions.length === 0}
              />
            </div>
            <div className="w-100 d-none d-sm-block" />
            <div className="col-12 col-sm-8 col-md-6 form-group">
              <label htmlFor="province">Provincia</label>
              <Field
                name="province"
                options={provincesOptions}
                placeholder="Selecciona..."
                component={Select}
                onChange={this.handleProvinceChange}
                isDisabled={provincesOptions.length === 0}
              />
            </div>
            <div className="w-100 d-none d-sm-block" />
            <div className="col-12 col-sm-8 col-md-6 form-group">
              <label htmlFor="commune">Comuna</label>
              <Field
                name="commune"
                options={communesOptions}
                placeholder="Selecciona..."
                component={Select}
                isDisabled={communesOptions.length === 0}
              />
            </div>
          </div>
          <div className="row justify-content-center mt30 buttons-container">
            <div className="col-12 col-sm-5 col-md-4">
              <button
                className="btn white back-to-client-info"
                type="button"
                onClick={previousPage}
              >
                Volver
              </button>
            </div>
            <div className="col-12 col-sm-5 col-md-4">
              <button className="btn primary go-to-dps" type="submit">
                Continuar
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SubscriptionFormSecondPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    email: PropTypes.string,
  }),
};

SubscriptionFormSecondPage.defaultProps = {
  quote: { email: '' },
};

export default SubscriptionFormSecondPage;
