import { combineReducers } from 'redux';
import * as constants from '../actions/constants';

const initialState = {
  rut: '',
  name: '',
  paternal: '',
  maternal: '',
  phone: '',
  email: '',
  birthdate: '',
  address: '',
  addressNumber: null,
  province: '',
  commune: '',
  declaration: false,
  plan: '',
  region: '',
  gender: '',
  heir: true,
};

const subscriptionForm = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_SUBSCRIPTION_FORM:
      return action.payload;
    default:
      return state;
  }
};

const initialStateProposal = {};

const proposal = (state = initialStateProposal, action) => {
  switch (action.type) {
    case constants.SET_PROPOSAL:
      return action.payload;
    default:
      return state;
  }
};

const initialStatePaymentType = 'CMR';

const payMethod = (state = initialStatePaymentType, action) => {
  switch (action.type) {
    case constants.SET_PAYMENT_TYPE:
      return action.payload;
    default:
      return state;
  }
};

const initialSubscriptionLoading = false;

const loading = (state = initialSubscriptionLoading, action) => {
  switch (action.type) {
    case constants.CHANGE_SUBSCRIPTION_LOADING:
      return action.payload;
    default:
      return state;
  }
};

const reducers = combineReducers({
  subscriptionForm,
  proposal,
  loading,
  payMethod,
});

export default reducers;
