const validatePercentage = values =>
  new Promise((resolve, reject) => {
    const percentageArray = [];
    Object.keys(values).forEach(key => {
      if (key.indexOf('beneficiary-percentage') > -1)
        percentageArray.push(parseFloat(values[key]));
    });
    const percentageSumatory = percentageArray.reduce((acc, val) => acc + val, 0);
    const errorMessage = 'sumatoria de porcentajes de beneficiario no puede ser mayor que 100';
    if (percentageSumatory > 100) {
      /*eslint-disable */
      return reject({
        'beneficiary-percentage-1': errorMessage,
        'beneficiary-percentage-2': errorMessage,
        'beneficiary-percentage-3': errorMessage,
        'beneficiary-percentage-4': errorMessage,
        'beneficiary-percentage-5': errorMessage,
        'beneficiary-percentage-6': errorMessage,
        'beneficiary-percentage-7': errorMessage,
      });
      /* eslint-enable */
    }
    return resolve();
  });
/*eslint-disable */
export { validatePercentage };
/* eslint-enable */
