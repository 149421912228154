import { connect } from 'react-redux';
import SubProductsComponent from '../components/SubProducts';
import { getSubProducts, getQuoteForm } from '../actions/selectors';
import { actionBackToQuote } from '../actions/creators';

export const mapStateToProps = state => ({
  subProducts: getSubProducts(state),
  clientInfo: getQuoteForm(state),
});

export const mapDispatchToProps = dispatch => ({
  backToQuote: () => dispatch(actionBackToQuote()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubProductsComponent);
