import { takeEvery } from 'redux-saga/effects';
import { quoteConstants, subscriptionConstants, commonsConstants } from './constants';
import commonEffects from './commons/actions/effects';
import quoteEffects from './quote/actions/effects';
import subscriptionEffects from './subscription/actions/effects';

function* sagas() {
  yield takeEvery(quoteConstants.SUBMIT_QUOTE_FORM, quoteEffects.submitQuoteForm);
  yield takeEvery(quoteConstants.SELECT_SUBPRODUCT, quoteEffects.selectSubProduct);
  yield takeEvery(
    subscriptionConstants.SUBMIT_SUBSCRIPTION_FORM,
    subscriptionEffects.submitSubscriptionForm,
  );
  yield takeEvery(
    subscriptionConstants.BACK_TO_SUBPRODUCTS,
    subscriptionEffects.backToSubProducts,
  );
  yield takeEvery(commonsConstants.HANDLE_ERROR, commonEffects.handleError);
  yield takeEvery(quoteConstants.BACK_TO_QUOTE, quoteEffects.backToQuote);
}

export default sagas;
