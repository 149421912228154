import { spawn } from 'redux-saga/effects';
import swal from 'sweetalert';

const commonEffects = {
  *handleError() {
    return yield spawn(swal, {
      title: 'Ha ocurrido un error',
      text: 'Por favor intente nuevamente y/o si el error persiste puede llamar al número 600 390 1000 o acercarse a alguna de nuestras sucursales.',
      icon: 'error',
      button: 'Cerrar',
    });
  },
};

export default commonEffects;
