import React from 'react';
import { Field } from 'redux-form';
import { diseases } from '../fixtures/data';

const DpsFormDesktop = () => (
  <div className="col-12">
    <table className="simple">
      <thead>
        <tr>
          <th aria-label="space" width="50%" />
          <th width="25%">¿Tiene o se encuentra en tratamiento?</th>
          <th width="25%">¿Tuve?</th>
        </tr>
      </thead>
      <tbody>
        {diseases.map(item => (
          <tr key={item.name}>
            <td>{item.name}</td>
            <td>
              <label
                className="custom-check custom-check__radio mr20"
                htmlFor={`no-tengo-${item.have}`}
              >
                <div className="custom-check--container">
                  <Field
                    id={`no-tengo-${item.have}`}
                    name={item.have}
                    component="input"
                    value="0"
                    type="radio"
                  />
                  <div className="custom-check--bg" />
                  <i />
                </div>
                <span>No</span>
              </label>

              <label
                className="custom-check custom-check__radio mr20"
                htmlFor={`si-tengo-${item.have}`}
              >
                <div className="custom-check--container">
                  <Field
                    id={`si-tengo-${item.have}`}
                    name={item.have}
                    component="input"
                    value="1"
                    type="radio"
                  />
                  <div className="custom-check--bg" />
                  <i />
                </div>
                <span>Si</span>
              </label>
            </td>
            <td>
              <label
                className="custom-check custom-check__radio mr20"
                htmlFor={`no-tuve-${item.had}`}
              >
                <div className="custom-check--container">
                  <Field
                    id={`no-tuve-${item.had}`}
                    name={item.had}
                    component="input"
                    value="0"
                    type="radio"
                  />
                  <div className="custom-check--bg" />
                  <i />
                </div>
                <span>No</span>
              </label>
              <label
                className="custom-check custom-check__radio mr20"
                htmlFor={`si-tuve-${item.had}`}
              >
                <div className="custom-check--container">
                  <Field
                    id={`si-tuve-${item.had}`}
                    name={item.had}
                    component="input"
                    value="1"
                    type="radio"
                  />
                  <div className="custom-check--bg" />
                  <i />
                </div>
                <span>Si</span>
              </label>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default DpsFormDesktop;
