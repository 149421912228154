import { reduxForm } from 'redux-form';
import Beneficiaries from '../components/Beneficiaries';
import { validatePercentage as asyncValidate } from '../fixtures/asyncValidate';
import validate from '../fixtures/validate';
import focusInputOnError from '../../commons/actions/focusInputOnError';

const form = reduxForm({
  form: 'subscription',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  asyncValidate,
  asyncBlurFields: [
    'beneficiary-percentage-1',
    'beneficiary-percentage-2',
    'beneficiary-percentage-3',
    'beneficiary-percentage-4',
    'beneficiary-percentage-5',
    'beneficiary-percentage-6',
    'beneficiary-percentage-7',
  ],
  onSubmitFail: focusInputOnError,
})(Beneficiaries);

export default form;
