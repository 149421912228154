import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import axios from 'axios';
import './stylus/global.css';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import { datadogRum } from '@datadog/browser-rum';
import store, { history } from './store';
import Quote from './quote/containers/Quote';
import SubProducts from './quote/containers/SubProducts';
import Subscription from './subscription/components/Subscription';
import Success from './success/containers/Success';
import { TOKEN_TYPE } from './constants';
import { getParameterFromURL } from './commons/helpers';
import ScrollTop from './ScrollTop';
import NotFound from './commons/components/not_found/NotFound';

const userIdParam = getParameterFromURL('buid');

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-T475GKT',
    dataLayerName: 'PageSubProducts',
    ...(userIdParam && {
      dataLayer: {
        userId: userIdParam,
      },
    }),
  };

  TagManager.initialize(tagManagerArgs);

  datadogRum.init({
    clientToken: process.env.REACT_APP_CLIENT_TOKEN,
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    site: process.env.REACT_APP_SITE_DD,
    service: process.env.REACT_APP_SERVICE_DD,
    env: process.env.REACT_APP_ENV_DD,
    version: '1.0.0',
    sampleRate: 50,
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  ReactGA.initialize('G-DMHZN1VDRG');
}

axios.defaults.baseURL = process.env.REACT_APP_BFF_BASE_URL;
axios.defaults.headers.common.authorization = `${TOKEN_TYPE} ${process.env.REACT_APP_BFF_TOKEN}`;

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollTop>
        <Switch>
          <Route exact path="/" component={Quote} />
          <Route path="/subproducts" component={SubProducts} />
          <Route path="/subscription" component={Subscription} />
          <Route path="/success" component={Success} />
          <Route component={NotFound} />
        </Switch>
      </ScrollTop>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));
