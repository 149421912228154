import { formatDateToIsoString } from '../../commons/helpers';

export const subscriptionForm = {
  rut: '123456789-0',
  name: 'John',
  paternal: 'Doe',
  maternal: 'Doe',
  phone: '942345678',
  email: 'test@email.com',
  birthdate: '10-10-1990',
  address: 'home',
  addressNumber: 123,
  region: 'metropolitana',
  province: 'santiago',
  commune: 'santiago',
  declaration: true,
  plan: 1,
  'beneficiary-birthdate-1': '12-12-2000',
  'beneficiary-maternal-1': 'maternal',
  'beneficiary-name-1': 'name',
  'beneficiary-paternal-1': 'paternal',
  'beneficiary-percentage-1': 100,
  'beneficiary-relationship-1': 1,
  'beneficiary-rut-1': '1-9',
  gender: 'MALE',
  cardNumber: '1111-1111-1111-1111',
  declarationCard: true,
  DPS_AVE_HAD: false,
  DPS_AVE_HAVE: true,
};

export const subscription = {
  subscriptionForm,
  loading: false,
  proposal: {},
};

export const proposalResponse = {
  proposal: 1,
};

export const subscriptionFormWithBeneficiaries = {
  rut: '123456789-0',
  name: 'John',
  paternal: 'Doe',
  maternal: 'Doe',
  phone: '942345678',
  email: 'test@email.com',
  birthdate: '10-10-1990',
  address: 'home',
  addressNumber: 123,
  region: 'metropolitana',
  province: 'santiago',
  commune: 'santiago',
  declaration: true,
  beneficiaries: 2,
  plan: 1,
  gender: 'MALE',
  'beneficiary-birthdate-1': '12-12-2000',
  'beneficiary-maternal-1': 'maternal',
  'beneficiary-name-1': 'name',
  'beneficiary-paternal-1': 'paternal',
  'beneficiary-percentage-1': 50,
  'beneficiary-relationship-1': 1,
  'beneficiary-rut-1': '1-9',
  'beneficiary-rut-2': '1-9',
  'beneficiary-birthdate-2': '12-12-2000',
  'beneficiary-maternal-2': 'maternal',
  'beneficiary-name-2': 'name',
  'beneficiary-paternal-2': 'paternal',
  'beneficiary-percentage-2': 50,
  'beneficiary-relationship-2': 1,
  cardNumber: '1111-1111-1111-1111',
  declarationCard: true,
};

export const validPercentages = {
  'beneficiary-percentage-1': 100,
  'beneficiary-percentage-2': 0,
  'beneficiary-percentage-3': 0,
  'beneficiary-percentage-4': 0,
  'beneficiary-percentage-5': 0,
  'beneficiary-percentage-6': 0,
  'beneficiary-percentage-7': 0,
  'key-invalid': 0,
};

export const invalidPercentages = {
  'beneficiary-percentage-1': 100,
  'beneficiary-percentage-2': 100,
  'beneficiary-percentage-3': 100,
  'beneficiary-percentage-4': 100,
  'beneficiary-percentage-5': 100,
  'beneficiary-percentage-6': 100,
  'beneficiary-percentage-7': 100,
};

export const mappedSubscriptionForm = {
  insured: {
    documentId: '123456789-0',
    name: 'John',
    paternal: 'Doe',
    maternal: 'Doe',
    phone: '+56942345678',
    email: 'test@email.com',
    birthdate: formatDateToIsoString('10-10-1990', 'DD-MM-YYYY'),
    address: 'home',
    addressNumber: 123,
    region: 'metropolitana',
    province: 'santiago',
    commune: 'santiago',
    gender: 'MALE',
  },
  beneficiaries: [
    {
      birthdate: formatDateToIsoString('12-12-2000', 'DD-MM-YYYY'),
      maternal: 'maternal',
      name: 'name',
      paternal: 'paternal',
      percentage: 100,
      relationship: 1,
      documentId: '1-9',
    },
  ],
  diseases: [
    {
      name: 'AVE',
      have: true,
      had: false,
    },
  ],
  payment: {},
};

export const mappedSubscriptionFormWithBeneficiaries = {
  insured: {
    documentId: '123456789-0',
    name: 'John',
    paternal: 'Doe',
    maternal: 'Doe',
    phone: '+56942345678',
    email: 'test@email.com',
    birthdate: formatDateToIsoString('10-10-1990', 'DD-MM-YYYY'),
    address: 'home',
    addressNumber: 123,
    region: 'metropolitana',
    province: 'santiago',
    commune: 'santiago',
    gender: 'MALE',
  },
  beneficiaries: [
    {
      birthdate: formatDateToIsoString('12-12-2000', 'DD-MM-YYYY'),
      maternal: 'maternal',
      name: 'name',
      paternal: 'paternal',
      percentage: 50,
      relationship: 1,
      documentId: '1-9',
    },
    {
      birthdate: formatDateToIsoString('12-12-2000', 'DD-MM-YYYY'),
      maternal: 'maternal',
      name: 'name',
      paternal: 'paternal',
      percentage: 50,
      relationship: 1,
      documentId: '1-9',
    },
  ],
  diseases: [],
  payment: {},
};

export const regions = [
  {
    name: 'Metropolitana de Santiago',
    value: 'METROPOLITANA',
  },
];

export const cities = [
  {
    name: 'Santiago',
    value: 'SANTIAGO',
  },
];

export const communes = [
  {
    name: 'Santiago',
    value: 'SANTIAGO',
  },
];

export const diseases = [
  {
    name: 'Cáncer (Declarar NO si pasaron 5 años o más desde la finalización del tratamiento radical sin recaída)',
    have: 'DPS_CAN_HAVE',
    had: 'DPS_CAN_HAD',
  },
  {
    name: 'Leucemia',
    have: 'DPS_LEU_HAVE',
    had: 'DPS_LEU_HAD',
  },
  {
    name: 'Enfermedades Cardiacas',
    have: 'DPS_ENC_HAVE',
    had: 'DPS_ENC_HAD',
  },
  {
    name: 'Hipertensión Arterial',
    have: 'DPS_HAR_HAVE',
    had: 'DPS_HAR_HAD',
  },
  {
    name: 'Diabetes',
    have: 'DPS_DIA_HAVE',
    had: 'DPS_DIA_HAD',
  },
  {
    name: 'Insuficiencia Renal Crónica',
    have: 'DPS_IRC_HAVE',
    had: 'DPS_IRC_HAD',
  },
  {
    name: 'Daño Hepático, Cirrosis o Pancreatitis',
    have: 'DPS_DCP_HAVE',
    had: 'DPS_DCP_HAD',
  },
  {
    name: 'Sida o VIH',
    have: 'DPS_VIH_HAVE',
    had: 'DPS_VIH_HAD',
  },
  {
    name: 'Enfermedad Pulmonar crónica, enfisema o fibrosis quística, otros.',
    have: 'DPS_EPC_HAVE',
    had: 'DPS_EPC_HAD',
  },
  {
    name: 'Epilepsia',
    have: 'DPS_EPI_HAVE',
    had: 'DPS_EPI_HAD',
  },
  {
    name: 'Accidente vascular encefálico, derrame o infarto al cerebro',
    have: 'DPS_AVE_HAVE',
    had: 'DPS_AVE_HAD',
  },
];

export const defaultPayMethod = 'OTHERS';

export const defaultDiseasesValues = {
  DPS_CAN_HAVE: '0',
  DPS_CAN_HAD: '0',
  DPS_LEU_HAVE: '0',
  DPS_LEU_HAD: '0',
  DPS_ENC_HAVE: '0',
  DPS_ENC_HAD: '0',
  DPS_HAR_HAVE: '0',
  DPS_HAR_HAD: '0',
  DPS_DIA_HAVE: '0',
  DPS_DIA_HAD: '0',
  DPS_IRC_HAVE: '0',
  DPS_IRC_HAD: '0',
  DPS_DCP_HAVE: '0',
  DPS_DCP_HAD: '0',
  DPS_VIH_HAVE: '0',
  DPS_VIH_HAD: '0',
  DPS_EPC_HAVE: '0',
  DPS_EPC_HAD: '0',
  DPS_EPI_HAVE: '0',
  DPS_EPI_HAD: '0',
  DPS_AVE_HAVE: '0',
  DPS_AVE_HAD: '0',
};

export const fixedMonths = [
  {
    value: '01',
    name: '01',
  },
  {
    value: '02',
    name: '02',
  },
  {
    value: '03',
    name: '03',
  },
  {
    value: '04',
    name: '04',
  },
  {
    value: '05',
    name: '05',
  },
  {
    value: '06',
    name: '06',
  },
  {
    value: '07',
    name: '07',
  },
  {
    value: '08',
    name: '08',
  },
  {
    value: '09',
    name: '09',
  },
  {
    value: '10',
    name: '10',
  },
  {
    value: '11',
    name: '11',
  },
  {
    value: '12',
    name: '12',
  },
];

export const fixedYears = () => {
  const actualYear = new Date().getFullYear();
  return [...Array(20).keys()].map(c => ({
    name: `${c + actualYear}`,
    value: `${c + actualYear}`,
  }));
};

export const genders = [
  {
    name: 'Masculino',
    value: 'MALE',
  },
  {
    name: 'Femenino',
    value: 'FEMALE',
  },
];

export const billingDayDetails = [
  {
    name: '5',
    value: '5',
  },
  {
    name: '15',
    value: '15',
  },
  {
    name: '25',
    value: '25',
  },
];

export const bankDetails = [
  {
    name: 'Banco BBVA',
    value: 'bbva',
  },
  {
    name: 'Banco Estado',
    value: 'estado',
  },
  {
    name: 'Banco Falabella',
    value: 'falabella',
  },
  {
    name: 'Banco Santander',
    value: 'santander',
  },
  {
    name: 'BCI',
    value: 'bci',
  },
  {
    name: 'Banco de Chile / Banco Edwards',
    value: 'chile',
  },
  {
    name: 'Scotiabank',
    value: 'scotiabank',
  },
];

export const paymentMethod = [
  {
    name: 'Webpay',
    shortName: 'OTHERS',
    url: '//sfestaticos.blob.core.windows.net/pmc/vida/assets/payment-home.png',
  },
];
