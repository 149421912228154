import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getQuoteForm } from '../actions/selectors';
import QuoteFormComponent from '../components/QuoteForm';
import validate from '../fixtures/validate';
import focusInputOnError from '../../commons/actions/focusInputOnError';

export const mapStateToProps = state => ({
  initialValues: getQuoteForm(state),
});

const form = reduxForm({
  form: 'quoteForm',
  validate,
  onSubmitFail: focusInputOnError,
})(QuoteFormComponent);

const connectedForm = connect(mapStateToProps)(form);

export default connectedForm;
