import React from 'react';
import ReactGA from 'react-ga4';
import { PropTypes } from 'prop-types';
import TagManager from 'react-gtm-module';
import Container from '../../layout/Container';
import FeriaProductos from './Feria';
import MandatePACSuccess from './MandatePACSuccess';

class SuccessView extends React.PureComponent {
  componentDidMount() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      ReactGA.event('funnel_step_viewed', {
        user_id: `CL_RUT_${window.sessionStorage.getItem('hashID')}`,
        funnel_name: 'contratacion-seguro',
        product_name: 'web-vida-legado',
        funnel_step_name: 'paso-exito',
        assist_type: 'autoasistido',
      });
    }
  }

  render() {
    const {
      proposal: { number, mandateId },
      subscriptionData,
      subProduct: { company, premiums },
      reset,
    } = this.props;
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const tagManagerArgs = {
        dataLayer: {
          event: 'vpv',
        },
        dataLayerName: 'SuccessView',
      };
      TagManager.dataLayer(tagManagerArgs);
      ReactGA.event('funnel_step_viewed', {
        user_id: `CL_RUT_${window.sessionStorage.getItem('hashID')}`,
        funnel_name: 'contratacion-seguro',
        product_name: 'web-vida-legado',
        funnel_step_name: 'paso-exito',
        assist_type: 'autoasistido',
      });
    }

    return (
      <Container>
        <div className="container mb30">
          <div className="row">
            <div className="col-12 col-md-10 wizard-card message message__success mt50">
              <div className="row">
                <div className="col-12 text-center message--title">
                  <h1>{`¡Felicitaciones ${subscriptionData.name}!`}</h1>
                </div>
                <div className="col-12 col-sm-10 mx-auto">
                  <div className="row">
                    <div className="col-12 col-sm-3 col-md-2 message--icon-container">
                      <i className="material-icons message--icon">check</i>
                    </div>
                    <div className="col-12 col-sm-9 col-md-10">
                      <p>
                        Ya tienes tu Seguro de Vida con la Compañía&nbsp;
                        {company.name}
                        {', a una prima mensual de '}
                        {premiums.monthly}
                        &nbsp;
                        {'UF.'}
                      </p>
                      <p>
                        Tu Nº de propuesta es:&nbsp;
                        <span className="text-bold">{number}</span>
                      </p>
                      <p>
                        En las próximas 72 horas hábiles enviaremos tu certificado de cobertura al
                        correo&nbsp;
                        <span className="text-bold">{subscriptionData.email}</span>
                      </p>
                    </div>
                  </div>
                </div>
                {mandateId && (
                  <MandatePACSuccess mandateId={mandateId} bankName={subscriptionData.nameBank} />
                )}
                <div className="col-12 col-sm-4 mx-auto mt20">
                  <button className="btn white btn--hover-green" type="button" onClick={reset}>
                    Adquirir otro seguro
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FeriaProductos />
      </Container>
    );
  }
}

SuccessView.propTypes = {
  proposal: PropTypes.shape({
    number: PropTypes.number.isRequired,
    mandateId: PropTypes.string,
  }).isRequired,
  reset: PropTypes.func.isRequired,
  subscriptionData: PropTypes.shape({
    rut: PropTypes.string,
    name: PropTypes.string.isRequired,
    paternal: PropTypes.string,
    maternal: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string.isRequired,
    birthdate: PropTypes.string,
    address: PropTypes.string,
    addressNumber: PropTypes.number,
    province: PropTypes.string,
    commune: PropTypes.string,
    declaration: PropTypes.bool,
    plan: PropTypes.string,
    region: PropTypes.string,
    gender: PropTypes.string,
    nameBank: PropTypes.string,
  }).isRequired,
  subProduct: PropTypes.shape({
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    premiums: PropTypes.shape({
      annual: PropTypes.number.isRequired,
      local: PropTypes.number.isRequired,
      monthly: PropTypes.number.isRequired,
      period: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }).isRequired,
    properties: PropTypes.shape({
      benefits: PropTypes.string.isRequired,
      campaign: PropTypes.string,
      characteristics: PropTypes.string,
      deductible: PropTypes.number.isRequired,
      details: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.string.isRequired,
  }).isRequired,
};

export default SuccessView;
