export default [
  {
    name: 'Arica',
    code: '15101',
    province_name: 'Arica',
    province_code: '151',
    region_name: 'Arica y Parinacota',
    region_number: 'XV',
    region_code: 'CL-AP',
  },
  {
    name: 'Camarones',
    code: '15102',
    province_name: 'Arica',
    province_code: '151',
    region_name: 'Arica y Parinacota',
    region_number: 'XV',
    region_code: 'CL-AP',
  },
  {
    name: 'Putre',
    code: '15201',
    province_name: 'Parinacota',
    province_code: '152',
    region_name: 'Arica y Parinacota',
    region_number: 'XV',
    region_code: 'CL-AP',
  },
  {
    name: 'General Lagos',
    code: '15202',
    province_name: 'Parinacota',
    province_code: '152',
    region_name: 'Arica y Parinacota',
    region_number: 'XV',
    region_code: 'CL-AP',
  },
  {
    name: 'Iquique',
    code: '01101',
    province_name: 'Iquique',
    province_code: '011',
    region_name: 'Tarapacá',
    region_number: 'I',
    region_code: 'CL-TA',
  },
  {
    name: 'Alto Hospicio',
    code: '01102',
    province_name: 'Iquique',
    province_code: '011',
    region_name: 'Tarapacá',
    region_number: 'I',
    region_code: 'CL-TA',
  },
  {
    name: 'Pozo Almonte',
    code: '01201',
    province_name: 'Tamarugal',
    province_code: '014',
    region_name: 'Tarapacá',
    region_number: 'I',
    region_code: 'CL-TA',
  },
  {
    name: 'Camiña',
    code: '01402',
    province_name: 'Tamarugal',
    province_code: '014',
    region_name: 'Tarapacá',
    region_number: 'I',
    region_code: 'CL-TA',
  },
  {
    name: 'Colchane',
    code: '01403',
    province_name: 'Tamarugal',
    province_code: '014',
    region_name: 'Tarapacá',
    region_number: 'I',
    region_code: 'CL-TA',
  },
  {
    name: 'Huara',
    code: '01404',
    province_name: 'Tamarugal',
    province_code: '014',
    region_name: 'Tarapacá',
    region_number: 'I',
    region_code: 'CL-TA',
  },
  {
    name: 'Pica',
    code: '01405',
    province_name: 'Tamarugal',
    province_code: '014',
    region_name: 'Tarapacá',
    region_number: 'I',
    region_code: 'CL-TA',
  },
  {
    name: 'Antofagasta',
    code: '02101',
    province_name: 'Antofagasta',
    province_code: '021',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'Mejillones',
    code: '02102',
    province_name: 'Antofagasta',
    province_code: '021',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'Sierra Gorda',
    code: '02103',
    province_name: 'Antofagasta',
    province_code: '021',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'Taltal',
    code: '02104',
    province_name: 'Antofagasta',
    province_code: '021',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'Calama',
    code: '02201',
    province_name: 'El Loa',
    province_code: '022',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'Ollagüe',
    code: '02202',
    province_name: 'El Loa',
    province_code: '022',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'San Pedro de Atacama',
    code: '02203',
    province_name: 'El Loa',
    province_code: '022',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'Tocopilla',
    code: '02301',
    province_name: 'Tocopilla',
    province_code: '023',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'María Elena',
    code: '02302',
    province_name: 'Tocopilla',
    province_code: '023',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'Copiapó',
    code: '03101',
    province_name: 'Copiapó',
    province_code: '031',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Caldera',
    code: '03102',
    province_name: 'Copiapó',
    province_code: '031',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Tierra Amarilla',
    code: '03103',
    province_name: 'Copiapó',
    province_code: '031',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Chañaral',
    code: '03201',
    province_name: 'Chañaral',
    province_code: '032',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Diego de Almagro',
    code: '03202',
    province_name: 'Chañaral',
    province_code: '032',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Vallenar',
    code: '03301',
    province_name: 'Huasco',
    province_code: '033',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Alto del Carmen',
    code: '03302',
    province_name: 'Huasco',
    province_code: '033',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Freirina',
    code: '03303',
    province_name: 'Huasco',
    province_code: '033',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Huasco',
    code: '03304',
    province_name: 'Huasco',
    province_code: '033',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'La Serena',
    code: '04101',
    province_name: 'Elqui',
    province_code: '041',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Coquimbo',
    code: '04102',
    province_name: 'Elqui',
    province_code: '041',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Andacollo',
    code: '04103',
    province_name: 'Elqui',
    province_code: '041',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'La Higuera',
    code: '04104',
    province_name: 'Elqui',
    province_code: '041',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Paihuano',
    code: '04105',
    province_name: 'Elqui',
    province_code: '041',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Vicuña',
    code: '04106',
    province_name: 'Elqui',
    province_code: '041',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Illapel',
    code: '04201',
    province_name: 'Choapa',
    province_code: '042',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Canela',
    code: '04202',
    province_name: 'Choapa',
    province_code: '042',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Los Vilos',
    code: '04203',
    province_name: 'Choapa',
    province_code: '042',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Salamanca',
    code: '04204',
    province_name: 'Choapa',
    province_code: '042',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Ovalle',
    code: '04301',
    province_name: 'Limarí',
    province_code: '043',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Combarbalá',
    code: '04302',
    province_name: 'Limarí',
    province_code: '043',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Monte Patria',
    code: '04303',
    province_name: 'Limarí',
    province_code: '043',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Punitaqui',
    code: '04304',
    province_name: 'Limarí',
    province_code: '043',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Río Hurtado',
    code: '04305',
    province_name: 'Limarí',
    province_code: '043',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Valparaíso',
    code: '05101',
    province_name: 'Valparaíso',
    province_code: '051',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Casablanca',
    code: '05102',
    province_name: 'Valparaíso',
    province_code: '051',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Concón',
    code: '05103',
    province_name: 'Valparaíso',
    province_code: '051',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Juan Fernández',
    code: '05104',
    province_name: 'Valparaíso',
    province_code: '051',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Puchuncaví',
    code: '05105',
    province_name: 'Valparaíso',
    province_code: '051',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Quintero',
    code: '05107',
    province_name: 'Valparaíso',
    province_code: '051',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Viña del Mar',
    code: '05109',
    province_name: 'Valparaíso',
    province_code: '051',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Isla de Pascua',
    code: '05201',
    province_name: 'Isla de Pascua',
    province_code: '052',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Los Andes',
    code: '05301',
    province_name: 'Los Andes',
    province_code: '053',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Calle Larga',
    code: '05302',
    province_name: 'Los Andes',
    province_code: '053',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Riconada',
    code: '05303',
    province_name: 'Los Andes',
    province_code: '053',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'San Esteban',
    code: '05304',
    province_name: 'Los Andes',
    province_code: '053',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'La Ligua',
    code: '05401',
    province_name: 'Petorca',
    province_code: '054',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Cabildo',
    code: '05402',
    province_name: 'Petorca',
    province_code: '054',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Papudo',
    code: '05403',
    province_name: 'Petorca',
    province_code: '054',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Petorca',
    code: '05404',
    province_name: 'Petorca',
    province_code: '054',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Zapallar',
    code: '05405',
    province_name: 'Petorca',
    province_code: '054',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Quillota',
    code: '05501',
    province_name: 'Quillota',
    province_code: '055',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Calera',
    code: '05502',
    province_name: 'Quillota',
    province_code: '055',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Hijuelas',
    code: '05503',
    province_name: 'Quillota',
    province_code: '055',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'La Cruz',
    code: '05504',
    province_name: 'Quillota',
    province_code: '055',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Nogales',
    code: '05506',
    province_name: 'Quillota',
    province_code: '055',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'San Antonio',
    code: '05601',
    province_name: 'San Antonio',
    province_code: '056',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Algarrobo',
    code: '05602',
    province_name: 'San Antonio',
    province_code: '056',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Cartagena',
    code: '05603',
    province_name: 'San Antonio',
    province_code: '056',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'El Quisco',
    code: '05604',
    province_name: 'San Antonio',
    province_code: '056',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'El Tabo',
    code: '05605',
    province_name: 'San Antonio',
    province_code: '056',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Santo Domingo',
    code: '05606',
    province_name: 'San Antonio',
    province_code: '056',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'San Felipe',
    code: '05701',
    province_name: 'San Felipe de Aconcagua',
    province_code: '057',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Catemu',
    code: '05702',
    province_name: 'San Felipe de Aconcagua',
    province_code: '057',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Llaillay',
    code: '05703',
    province_name: 'San Felipe de Aconcagua',
    province_code: '057',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Panquehue',
    code: '05704',
    province_name: 'San Felipe de Aconcagua',
    province_code: '057',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Putaendo',
    code: '05705',
    province_name: 'San Felipe de Aconcagua',
    province_code: '057',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Santa María',
    code: '05706',
    province_name: 'San Felipe de Aconcagua',
    province_code: '057',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Quilpué',
    code: '05801',
    province_name: 'Marga Marga',
    province_code: '058',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Limache',
    code: '05802',
    province_name: 'Marga Marga',
    province_code: '058',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Olmué',
    code: '05803',
    province_name: 'Marga Marga',
    province_code: '058',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Villa Alemana',
    code: '05804',
    province_name: 'Marga Marga',
    province_code: '058',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Rancagua',
    code: '06101',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Codegua',
    code: '06102',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Coinco',
    code: '06103',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Coltauco',
    code: '06104',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Doñihue',
    code: '06105',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Graneros',
    code: '06106',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Las Cabras',
    code: '06107',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Machalí',
    code: '06108',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Malloa',
    code: '06109',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Mostazal',
    code: '06110',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Olivar',
    code: '06111',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Peumo',
    code: '06112',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Pichidegua',
    code: '06113',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Quinta de Tilcoco',
    code: '06114',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Rengo',
    code: '06115',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Requínoa',
    code: '06116',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'San Vicente',
    code: '06117',
    province_name: 'Cachapoal',
    province_code: '061',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Pichilemu',
    code: '06201',
    province_name: 'Cardenal Caro',
    province_code: '062',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'La Estrella',
    code: '06202',
    province_name: 'Cardenal Caro',
    province_code: '062',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Litueche',
    code: '06203',
    province_name: 'Cardenal Caro',
    province_code: '062',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Marichihue',
    code: '06204',
    province_name: 'Cardenal Caro',
    province_code: '062',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Navidad',
    code: '06205',
    province_name: 'Cardenal Caro',
    province_code: '062',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Paredones',
    code: '06206',
    province_name: 'Cardenal Caro',
    province_code: '062',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'San Fernando',
    code: '06301',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Chépica',
    code: '06302',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Chimbarongo',
    code: '06303',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Lolol',
    code: '06304',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Nancagua',
    code: '06305',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Palmilla',
    code: '06306',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Peralillo',
    code: '06307',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Placilla',
    code: '06308',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Pumanque',
    code: '06309',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Santa Cruz',
    code: '06310',
    province_name: 'Colchagua',
    province_code: '063',
    region_name: 'Región del Libertador Gral. Bernardo O"Higgins',
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Talca',
    code: '07101',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Constitución',
    code: '07102',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Curepto',
    code: '07103',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Empedrado',
    code: '07104',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Maule',
    code: '07105',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Pelarco',
    code: '07106',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Pencahue',
    code: '07107',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Río Claro',
    code: '07108',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'San Clemente',
    code: '07109',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'San Rafael',
    code: '07110',
    province_name: 'Talca',
    province_code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Cauquenes',
    code: '07201',
    province_name: 'Cauquenes',
    province_code: '072',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Chanco',
    code: '07202',
    province_name: 'Cauquenes',
    province_code: '072',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Pelluhue',
    code: '07203',
    province_name: 'Cauquenes',
    province_code: '072',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Curicó',
    code: '07301',
    province_name: 'Curicó',
    province_code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Hualañé',
    code: '07302',
    province_name: 'Curicó',
    province_code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Licantén',
    code: '07303',
    province_name: 'Curicó',
    province_code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Molina',
    code: '07304',
    province_name: 'Curicó',
    province_code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Rauco',
    code: '07305',
    province_name: 'Curicó',
    province_code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Romeral',
    code: '07306',
    province_name: 'Curicó',
    province_code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Sagrada Familia',
    code: '07307',
    province_name: 'Curicó',
    province_code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Teno',
    code: '07308',
    province_name: 'Curicó',
    province_code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Vichuquén',
    code: '07309',
    province_name: 'Curicó',
    province_code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Linares',
    code: '07401',
    province_name: 'Linares',
    province_code: '074',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Colbún',
    code: '07402',
    province_name: 'Linares',
    province_code: '074',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Longaví',
    code: '07403',
    province_name: 'Linares',
    province_code: '074',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Parral',
    code: '07404',
    province_name: 'Linares',
    province_code: '074',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Retiro',
    code: '07405',
    province_name: 'Linares',
    province_code: '074',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'San Javier',
    code: '07406',
    province_name: 'Linares',
    province_code: '074',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Villa Alegre',
    code: '07407',
    province_name: 'Linares',
    province_code: '074',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Yerbas Buenas',
    code: '07408',
    province_name: 'Linares',
    province_code: '074',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Concepción',
    code: '08101',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Coronel',
    code: '08102',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Chiguayante',
    code: '08103',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Florida',
    code: '08104',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Hualqui',
    code: '08105',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Lota',
    code: '08106',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Penco',
    code: '08107',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'San Pedro de la Paz',
    code: '08108',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Santa Juana',
    code: '08109',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Talcahuano',
    code: '08110',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Tomé',
    code: '08111',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Hualpén',
    code: '08112',
    province_name: 'Concepción',
    province_code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Lebu',
    code: '08201',
    province_name: 'Arauco',
    province_code: '082',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Arauco',
    code: '08202',
    province_name: 'Arauco',
    province_code: '082',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Cañete',
    code: '08203',
    province_name: 'Arauco',
    province_code: '082',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Contulmo',
    code: '08204',
    province_name: 'Arauco',
    province_code: '082',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Curanilahue',
    code: '08205',
    province_name: 'Arauco',
    province_code: '082',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Los Álamos',
    code: '08206',
    province_name: 'Arauco',
    province_code: '082',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Tirúa',
    code: '08207',
    province_name: 'Arauco',
    province_code: '082',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Los Ángeles',
    code: '08301',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Antuco',
    code: '08302',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Cabrero',
    code: '08303',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Laja',
    code: '08304',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Mulchén',
    code: '08305',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Nacimiento',
    code: '08306',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Negrete',
    code: '08307',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Quilaco',
    code: '08308',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Quilleco',
    code: '08309',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'San Rosendo',
    code: '08310',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Santa Bárbara',
    code: '08311',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Tucapel',
    code: '08312',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Yumbel',
    code: '08313',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Alto Biobío',
    code: '08314',
    province_name: 'Biobío',
    province_code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Chillán',
    code: '08401',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Bulnes',
    code: '08402',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Cobquecura',
    code: '08403',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Coelemu',
    code: '08404',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Coihueco',
    code: '08405',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Chillán Viejo',
    code: '08406',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'El Carmen',
    code: '08407',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Ninhue',
    code: '08408',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Ñiquén',
    code: '08409',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Pemuco',
    code: '08410',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Pinto',
    code: '08411',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Portezuelo',
    code: '08412',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Quillón',
    code: '08413',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Quirihue',
    code: '08414',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Ránquil',
    code: '08415',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'San Carlos',
    code: '08416',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'San Fabián',
    code: '08417',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'San Ignacio',
    code: '08418',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'San Nicolás',
    code: '08419',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Treguaco',
    code: '08420',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Yungay',
    code: '08421',
    province_name: 'Ñuble',
    province_code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Temuco',
    code: '09101',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Carahue',
    code: '09102',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Cunco',
    code: '09103',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Curarrehue',
    code: '09104',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Freire',
    code: '09105',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Galvarino',
    code: '09106',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Gorbea',
    code: '09107',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Lautaro',
    code: '09108',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Loncoche',
    code: '09109',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Melipeuco',
    code: '09110',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Nueva Imperial',
    code: '09111',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Padre las Casas',
    code: '09112',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Perquenco',
    code: '09113',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Pitrufquén',
    code: '09114',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Pucón',
    code: '09115',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Saavedra',
    code: '09116',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Teodoro Schmidt',
    code: '09117',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Toltén',
    code: '09118',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Vilcún',
    code: '09119',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Villarrica',
    code: '09120',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Cholchol',
    code: '09121',
    province_name: 'Cautín',
    province_code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Angol',
    code: '09201',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Collipulli',
    code: '09202',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Curacautín',
    code: '09203',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Ercilla',
    code: '09204',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Lonquimay',
    code: '09205',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Los Sauces',
    code: '09206',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Lumaco',
    code: '09207',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Purén',
    code: '09208',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Renaico',
    code: '09209',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Traiguén',
    code: '09210',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Victoria',
    code: '09211',
    province_name: 'Malleco',
    province_code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Valdivia',
    code: '14101',
    province_name: 'Valdivia',
    province_code: '141',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Corral',
    code: '14102',
    province_name: 'Valdivia',
    province_code: '141',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Lanco',
    code: '14103',
    province_name: 'Valdivia',
    province_code: '141',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Los Lagos',
    code: '14104',
    province_name: 'Valdivia',
    province_code: '141',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Máfil',
    code: '14105',
    province_name: 'Valdivia',
    province_code: '141',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Mariquina',
    code: '14106',
    province_name: 'Valdivia',
    province_code: '141',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Paillaco',
    code: '14107',
    province_name: 'Valdivia',
    province_code: '141',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Panguipulli',
    code: '14108',
    province_name: 'Valdivia',
    province_code: '141',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'La Unión',
    code: '14201',
    province_name: 'Ranco',
    province_code: '142',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Futrono',
    code: '14201',
    province_name: 'Ranco',
    province_code: '142',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Lago Ranco',
    code: '14201',
    province_name: 'Ranco',
    province_code: '142',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Río Bueno',
    code: '14201',
    province_name: 'Ranco',
    province_code: '142',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Puerto Montt',
    code: '10101',
    province_name: 'Llanquihue',
    province_code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Calbuco',
    code: '10102',
    province_name: 'Llanquihue',
    province_code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Cochamó',
    code: '10103',
    province_name: 'Llanquihue',
    province_code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Fresia',
    code: '10104',
    province_name: 'Llanquihue',
    province_code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Frutillar',
    code: '10105',
    province_name: 'Llanquihue',
    province_code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Los Muermos',
    code: '10106',
    province_name: 'Llanquihue',
    province_code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Llanquihue',
    code: '10107',
    province_name: 'Llanquihue',
    province_code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Maullín',
    code: '10108',
    province_name: 'Llanquihue',
    province_code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Puerto Varas',
    code: '10109',
    province_name: 'Llanquihue',
    province_code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Castro',
    code: '10201',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Ancud',
    code: '10202',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Chonchi',
    code: '10203',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Curaco de Vélez',
    code: '10204',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Dalcahue',
    code: '10205',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Puqueldón',
    code: '10206',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Queilén',
    code: '10207',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Quellón',
    code: '10208',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Quemchi',
    code: '10209',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Quinchao',
    code: '10210',
    province_name: 'Chiloé',
    province_code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Osorno',
    code: '10301',
    province_name: 'Osorno',
    province_code: '103',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Puerto Octay',
    code: '10302',
    province_name: 'Osorno',
    province_code: '103',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Purranque',
    code: '10303',
    province_name: 'Osorno',
    province_code: '103',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Puyehue',
    code: '10304',
    province_name: 'Osorno',
    province_code: '103',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Río Negro',
    code: '10305',
    province_name: 'Osorno',
    province_code: '103',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'San Juan de la Costa',
    code: '10306',
    province_name: 'Osorno',
    province_code: '103',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'San Pablo',
    code: '10307',
    province_name: 'Osorno',
    province_code: '103',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Chaitén',
    code: '10401',
    province_name: 'Palena',
    province_code: '104',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Futaleufú',
    code: '10402',
    province_name: 'Palena',
    province_code: '104',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Hualaihué',
    code: '10403',
    province_name: 'Palena',
    province_code: '104',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Palena',
    code: '10404',
    province_name: 'Palena',
    province_code: '104',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Coihaique',
    code: '11101',
    province_name: 'Coihaique',
    province_code: '111',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Lago Verde',
    code: '11102',
    province_name: 'Coihaique',
    province_code: '111',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Aisén',
    code: '11201',
    province_name: 'Aisén',
    province_code: '112',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Cisnes',
    code: '11202',
    province_name: 'Aisén',
    province_code: '112',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Guaitecas',
    code: '11203',
    province_name: 'Aisén',
    province_code: '112',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Cochrane',
    code: '11301',
    province_name: 'Capitán Pratt',
    province_code: '113',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'O’Higgins',
    code: '11302',
    province_name: 'Capitán Pratt',
    province_code: '113',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Tortel',
    code: '11303',
    province_name: 'Capitán Pratt',
    province_code: '113',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Chile Chico',
    code: '11401',
    province_name: 'General Carrera',
    province_code: '114',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Río Ibáñez',
    code: '11402',
    province_name: 'General Carrera',
    province_code: '114',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Punta Arenas',
    code: '12101',
    province_name: 'Magallanes',
    province_code: '121',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Laguna Blanca',
    code: '12102',
    province_name: 'Magallanes',
    province_code: '121',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Río Verde',
    code: '12103',
    province_name: 'Magallanes',
    province_code: '121',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'San Gregorio',
    code: '12104',
    province_name: 'Magallanes',
    province_code: '121',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Cabo de Hornos (Ex. Navarino)',
    code: '12201',
    province_name: 'Antártica Chilena',
    province_code: '122',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Antártica',
    code: '12202',
    province_name: 'Antártica Chilena',
    province_code: '122',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Porvenir',
    code: '12301',
    province_name: 'Tierra del Fuego',
    province_code: '123',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Primavera',
    code: '12302',
    province_name: 'Tierra del Fuego',
    province_code: '123',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Timaukel',
    code: '12303',
    province_name: 'Tierra del Fuego',
    province_code: '123',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Natales',
    code: '12401',
    province_name: 'Última Esperanza',
    province_code: '124',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Torres del Paine',
    code: '12402',
    province_name: 'Última Esperanza',
    province_code: '124',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Santiago',
    code: '13101',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Cerrillos',
    code: '13102',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Cerro Navia',
    code: '13103',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Conchalí',
    code: '13104',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'El Bosque',
    code: '13105',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Estación Central',
    code: '13106',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Huechuraba',
    code: '13107',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Independencia',
    code: '13108',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'La Cisterna',
    code: '13109',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'La Florida',
    code: '13110',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'La Granja',
    code: '13111',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'La Pintana',
    code: '13112',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'La Reina',
    code: '13113',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Las Condes',
    code: '13114',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Lo Barnechea',
    code: '13115',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Lo Espejo',
    code: '13116',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Lo Prado',
    code: '13117',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Macul',
    code: '13118',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Maipú',
    code: '13119',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Ñuñoa',
    code: '13120',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Pedro Aguirre Cerda',
    code: '13121',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Peñalolén',
    code: '13122',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Providencia',
    code: '13123',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Pudahuel',
    code: '13124',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Quilicura',
    code: '13125',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Quinta Normal',
    code: '13126',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Recoleta',
    code: '13127',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Renca',
    code: '13128',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'San Joaquín',
    code: '13129',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'San Miguel',
    code: '13130',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'San Ramón',
    code: '13131',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Vitacura',
    code: '13132',
    province_name: 'Santiago',
    province_code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Puente Alto',
    code: '13201',
    province_name: 'Cordillera',
    province_code: '132',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Pirque',
    code: '13202',
    province_name: 'Cordillera',
    province_code: '132',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'San José de Maipo',
    code: '13203',
    province_name: 'Cordillera',
    province_code: '132',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Colina',
    code: '13301',
    province_name: 'Chacabuco',
    province_code: '133',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Lampa',
    code: '13302',
    province_name: 'Chacabuco',
    province_code: '133',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Tiltil',
    code: '13303',
    province_name: 'Chacabuco',
    province_code: '133',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'San Bernardo',
    code: '13401',
    province_name: 'Maipo',
    province_code: '134',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Buin',
    code: '13402',
    province_name: 'Maipo',
    province_code: '134',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Calera de Tango',
    code: '13403',
    province_name: 'Maipo',
    province_code: '134',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Paine',
    code: '13404',
    province_name: 'Maipo',
    province_code: '134',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Melipilla',
    code: '13501',
    province_name: 'Mellipilla',
    province_code: '135',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Alhué',
    code: '13502',
    province_name: 'Mellipilla',
    province_code: '135',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Curacaví',
    code: '13503',
    province_name: 'Mellipilla',
    province_code: '135',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'María Pinto',
    code: '13504',
    province_name: 'Mellipilla',
    province_code: '135',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'San Pedro',
    code: '13505',
    province_name: 'Mellipilla',
    province_code: '135',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Talagante',
    code: '13601',
    province_name: 'Talagante',
    province_code: '136',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'El Monte',
    code: '13602',
    province_name: 'Talagante',
    province_code: '136',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Isla de Maipo',
    code: '13603',
    province_name: 'Talagante',
    province_code: '136',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Padre Hurtado',
    code: '13604',
    province_name: 'Talagante',
    province_code: '136',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Peñaflor',
    code: '13605',
    province_name: 'Talagante',
    province_code: '136',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
];
