import { put, select, call } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { actionSetProposal, actionSetSubscriptionForm, actionSetLoading } from './creators';
import { actionHandleError } from '../../commons/actions/creators';
import {
  getQuoteResponse,
  getSelectedSubProduct,
  getUUID,
  getClosingToken,
} from '../../quote/actions/selectors';
import { DOCUMENT_ID_FIELD } from './constants';
import { URL_SUBSCRIPTION, CLOSING_TOKEN_HEADER_NAME } from '../../constants';
import {
  formatDateToIsoString,
  parserRut,
  obfuscateCardNumber,
  encryptCardNumber,
  formatPrice,
} from '../../commons/helpers';

const mapDPS = payload =>
  Object.keys(payload)
    .map(el => el.split('_')[1])
    .filter((x, i, a) => a.indexOf(x) === i)
    .map(name => ({
      name,
      have: !!+payload[`DPS_${name}_HAVE`],
      had: !!+payload[`DPS_${name}_HAD`],
    }));

const mapBeneficiaries = payload => {
  const beneficiaryPrefix = 'beneficiary';
  const beneficiaries = [];
  if (!payload.heir) {
    Object.keys(payload).forEach(key => {
      if (key.indexOf(beneficiaryPrefix) > -1) {
        const splittedKey = key.split('-');
        const sufix = parseInt(splittedKey[2], 10);
        const beneficiary = beneficiaries.find(elem => elem.ordinal === sufix);
        if (beneficiary) {
          if (splittedKey[1] === DOCUMENT_ID_FIELD) beneficiary.documentId = payload[key];
          else beneficiary[splittedKey[1]] = payload[key];
        } else {
          const newBeneficiary = {
            ordinal: sufix,
          };
          if (splittedKey[1] === DOCUMENT_ID_FIELD) newBeneficiary.documentId = payload[key];
          else newBeneficiary[splittedKey[1]] = payload[key];
          beneficiaries.push(newBeneficiary);
        }
      }
    });
  } else {
    beneficiaries.push({
      documentId: payload.rut,
      birthdate: payload.birthdate,
      name: payload.name,
      paternal: payload.paternal,
      maternal: payload.maternal,
      relationship: 'OTHERS',
      percentage: '100',
    });
  }
  const formattedBeneficiaries = beneficiaries.map(beneficiary => {
    const { ordinal, ...rest } = beneficiary;
    return {
      ...rest,
      birthdate: formatDateToIsoString(beneficiary.birthdate, 'DD-MM-YYYY'),
      documentId: parserRut(beneficiary.documentId),
    };
  });

  return formattedBeneficiaries;
};

const mapInsured = payload => ({
  address: payload.address,
  birthdate: formatDateToIsoString(payload.birthdate, 'DD-MM-YYYY'),
  addressNumber: payload.addressNumber,
  province: payload.province,
  commune: payload.commune,
  email: payload.email,
  maternal: payload.maternal,
  name: payload.name,
  paternal: payload.paternal,
  phone: `+56${payload.phone}`,
  region: payload.region,
  documentId: parserRut(payload.rut),
  gender: payload.gender,
});

const mapPayment = payload => {
  switch (payload.payMethod) {
    case 'CMR':
      return {
        method: 'CMR',
        cardNumber: obfuscateCardNumber(payload.cardNumber),
      };
    case 'OTHERS':
      return {
        method: 'OTHERS',
        cardNumber: encryptCardNumber(payload.creditNumber),
        expiryMonth: payload.cardMonth,
        expiryYear: payload.cardYear,
      };
    case 'MANDATE':
      return {
        method: 'MANDATE',
        nameBank: payload.nameBank,
        accountBank: payload.accountBank.trim(),
        billingDay: Number(payload.billingDay),
      };
    default:
      return {};
  }
};

const filteredDps = payload =>
  Object.keys(payload)
    .filter(key => key.includes('DPS_'))
    .reduce((acc, cur) => ({ ...acc, [cur]: payload[cur] }), {});

const mapPayload = payload => {
  const dps = filteredDps(payload);
  const beneficiaries = mapBeneficiaries(payload);
  const insured = mapInsured(payload);
  const diseases = mapDPS(dps);
  const payment = mapPayment(payload);

  return {
    beneficiaries,
    insured,
    diseases,
    payment,
  };
};

const subscriptionActions = {
  *submitSubscriptionForm({ payload }) {
    try {
      yield put(actionSetLoading(true));
      const { control, ...quote } = yield select(getQuoteResponse);
      const selectedSubProduct = yield select(getSelectedSubProduct);
      const uuid = yield select(getUUID);
      const closingToken = yield select(getClosingToken);

      const customPayload = { ...payload };
      delete customPayload.loading;
      delete customPayload.proposal;
      const mappedPayload = mapPayload(customPayload);

      yield put(actionSetSubscriptionForm(payload));

      const closureData = {
        control,
        executive: {
          channel: 'INTERNET',
          code: '33285',
        },
        product: {
          company: {
            id: selectedSubProduct.company.id,
            name: selectedSubProduct.company.name,
          },
          properties: {
            id: selectedSubProduct.properties.id,
            name: selectedSubProduct.properties.name,
          },
          premiums: {
            monthly: selectedSubProduct.premiums.monthly,
            local: formatPrice(selectedSubProduct.premiums.local),
            annual: selectedSubProduct.premiums.annual,
          },
        },
        quote,
        payload: mappedPayload,
      };
      const config = {
        headers: {
          'x-trace': uuid,
          [CLOSING_TOKEN_HEADER_NAME]: closingToken,
        },
      };

      const { data } = yield call(axios.post, URL_SUBSCRIPTION, closureData, config);
      yield put(actionSetProposal(data));
      yield put(push('/success'));
    } catch (error) {
      yield put(actionHandleError());
      yield put(actionSetLoading(false));
    }
  },
  *backToSubProducts() {
    try {
      yield put(push('/subproducts'));
    } catch (error) {
      yield put(actionHandleError());
    }
  },
};

export default subscriptionActions;
