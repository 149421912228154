import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SubscriptionFormSecondPage from '../components/SubscriptionFormSecondPage';
import validate from '../fixtures/validate';
import { getQuoteForm } from '../../quote/actions/selectors';
import focusInputOnError from '../../commons/actions/focusInputOnError';

export const mapStateToProps = state => ({
  quote: getQuoteForm(state),
});

const form = reduxForm({
  form: 'subscription',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: focusInputOnError,
})(SubscriptionFormSecondPage);

const connectedSubscriptionForm = connect(mapStateToProps)(form);

export default connectedSubscriptionForm;
