import { HANDLE_ERROR, SET_INITIAL_STATE } from './constants';

const actionHandleError = () => ({
  type: HANDLE_ERROR,
});

const initialState = () => ({
  type: SET_INITIAL_STATE,
});

export { actionHandleError, initialState };
