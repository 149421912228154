export default [
  {
    name: 'Arica',
    code: '151',
    region_name: 'Arica y Parinacota',
    region_number: 'XV',
    region_code: 'CL-AP',
  },
  {
    name: 'Parinacota',
    code: '152',
    region_name: 'Arica y Parinacota',
    region_number: 'XV',
    region_code: 'CL-AP',
  },
  {
    name: 'Iquique',
    code: '011',
    region_name: 'Tarapacá',
    region_number: 'I',
    region_code: 'CL-TA',
  },
  {
    name: 'Tamarugal',
    code: '014',
    region_name: 'Tarapacá',
    region_number: 'I',
    region_code: 'CL-TA',
  },
  {
    name: 'Antofagasta',
    code: '021',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'El Loa',
    code: '022',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'Tocopilla',
    code: '023',
    region_name: 'Antofagasta',
    region_number: 'II',
    region_code: 'CL-AN',
  },
  {
    name: 'Copiapó',
    code: '031',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Chañaral',
    code: '032',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Huasco',
    code: '033',
    region_name: 'Atacama',
    region_number: 'III',
    region_code: 'CL-AT',
  },
  {
    name: 'Elqui',
    code: '041',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Choapa',
    code: '042',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Limarí',
    code: '043',
    region_name: 'Coquimbo',
    region_number: 'IV',
    region_code: 'CL-CO',
  },
  {
    name: 'Valparaíso',
    code: '051',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Isla de Pascua',
    code: '052',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Los Andes',
    code: '053',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Petorca',
    code: '054',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Quillota',
    code: '055',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'San Antonio',
    code: '056',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'San Felipe de Aconcagua',
    code: '057',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Marga Marga',
    code: '058',
    region_name: 'Valparaíso',
    region_number: 'V',
    region_code: 'CL-VS',
  },
  {
    name: 'Cachapoal',
    code: '061',
    region_name: "Región del Libertador Gral. Bernardo O'Higgins",
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Cardenal Caro',
    code: '062',
    region_name: "Región del Libertador Gral. Bernardo O'Higgins",
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Colchagua',
    code: '063',
    region_name: "Región del Libertador Gral. Bernardo O'Higgins",
    region_number: 'VI',
    region_code: 'CL-LI',
  },
  {
    name: 'Talca',
    code: '071',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Cauquenes',
    code: '072',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Curicó',
    code: '073',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Linares',
    code: '074',
    region_name: 'Región del Maule',
    region_number: 'VII',
    region_code: 'CL-ML',
  },
  {
    name: 'Concepción',
    code: '081',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Arauco',
    code: '082',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Biobío',
    code: '083',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Ñuble',
    code: '084',
    region_name: 'Región del Biobío',
    region_number: 'VIII',
    region_code: 'CL-BI',
  },
  {
    name: 'Cautín',
    code: '091',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Malleco',
    code: '092',
    region_name: 'Región de la Araucanía',
    region_number: 'IX',
    region_code: 'CL-AR',
  },
  {
    name: 'Valdivia',
    code: '141',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Ranco',
    code: '142',
    region_name: 'Región de los Ríos',
    region_number: 'XIV',
    region_code: 'CL-LR',
  },
  {
    name: 'Llanquihue',
    code: '101',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Chiloé',
    code: '102',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Osorno',
    code: '103',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Palena',
    code: '104',
    region_name: 'Región de los Lagos',
    region_number: 'X',
    region_code: 'CL-LL',
  },
  {
    name: 'Coyhaique',
    code: '111',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Aisén',
    code: '112',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Capitán Pratt',
    code: '113',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'General Carrera',
    code: '114',
    region_name: 'Región Aisén del Gral. Carlos Ibañez del Campo',
    region_number: 'XI',
    region_code: 'CL-AI',
  },
  {
    name: 'Magallanes',
    code: '121',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Antártica Chilena',
    code: '122',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Tierra del Fuego',
    code: '123',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Última Esperanza',
    code: '124',
    region_name: 'Región de Magallanes y de la Antártica Chilena',
    region_number: 'XII',
    region_code: 'CL-MA',
  },
  {
    name: 'Santiago',
    code: '131',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Cordillera',
    code: '132',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Chacabuco',
    code: '133',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Maipo',
    code: '134',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Mellipilla',
    code: '135',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
  {
    name: 'Talagante',
    code: '136',
    region_name: 'Región Metropolitana de Santiago',
    region_number: 'XIII',
    region_code: 'CL-RM',
  },
];
