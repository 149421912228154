import React from 'react';

const Loading = () => (
  <div className="col-12 mt50 mb30">
    <div className="spinner m-auto">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
      <div className="bounce4" />
    </div>
  </div>
);

export default Loading;
